import React, { useMemo } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AuthRoutes,
  userAuthorityPath,
  partnerAuthorityPath,
  subBrokerAuthorityPath,
  mobileAuthority,
  viewUserAuthorityPath,
  AdminRouters,
  MasterRouters,
} from "./routesConfig";
import Sidebar from "components/Sidebar/Sidebar";
import AdminNavbar from "components/Navbars/AdminNavbar";
import "../assets/scss/mobile/styleMobile.scss";
import MobileNewFooter from "components/Mobile/MobileNewFooter";

const ProtectedRoute = ({ component: Component, ...props }) => {
  const location = useLocation();
  const mainContent = React.useRef(null);
  const { isUserAuthenticated, userRole, device, isMasterRole, isLoading } = useSelector(
    ({ auth }) => auth
  );
  const { rightsSessionList, isNavbarHidden } = useSelector(({ common }) => common);
  const { rulesAccepted, isShowChangePassword } = useSelector(({ auth }) => auth);
  const isAllowShow = (routeName, userRole) => {
    if (rightsSessionList?.length === 0) {
      return true
    }
    let isViewAuthority = true;
    if (routeName === "/" || userRole === "Super Admin") {
      return true;
    } else if (userRole === "User") {
      return userAuthorityPath.includes(routeName);
    } else if (userRole === "Partner") {
      return partnerAuthorityPath.includes(routeName);
    } else if (userRole === "Sub Broker") {
      return subBrokerAuthorityPath.includes(routeName);
    } else {
      if (!isMasterRole && viewUserAuthorityPath.includes(routeName)) {
        isViewAuthority = false;
      } else {
        if (AdminRouters.includes(routeName) && (userRole === "Admin" || userRole === "Super Master")) {
          return true
        } else if (MasterRouters.includes(routeName) && userRole === "Master") {
          return true
        } else {
          let isView = rightsSessionList.find((a) => routeName === a.routeName);
          if (isView?.isViewAllow) {
            isViewAuthority = true;
          } else {
            isViewAuthority = false;
          }
        }
      }
      return isViewAuthority;
    }
  };
  const isAllowShowMobile = (routeName) => {
    if (device === "Mobile" && mobileAuthority.includes(routeName)) {
      return true;
    } else {
      return false;
    }
  };
  const loggedInUrl = useMemo(() => {
    return sessionStorage.getItem("loggedInUrl");
  }, []);
  const headerNotShown = [
    "/app/add-script",
    "/app/add-script/script",
    "/app/trade-report",
    "/app/ledger-report",
    "/app/change-password",
    "/app/rejection-logs",
    "/app/trade-edit-delete-logs",
    "/app/banned-block-script",
    "/app/qty-setting",
    "/app/qty-setting/qty-inner-setting",
    "/app/rules"
  ]
  if (!isUserAuthenticated) {
    return (
      <Redirect
        exact
        to={{
          pathname: loggedInUrl ? loggedInUrl : "/login",
          state: {
            from: props.location,
          },
        }}
      />
    );
  }
  return (
    <>
      {!isLoading && (device !== "Mobile" ? (
        <>
          {((location.pathname === '/profile/rules' && !rulesAccepted) || (location.pathname === '/profile/change-password' && isShowChangePassword)) ? <Component {...props} /> :
            <>
              <Sidebar
                {...props}
                routes={AuthRoutes}
                logo={{
                  innerLink: "/",
                  imgSrc: require("assets/img/avatars/avatar-1.jpg").default,
                  imgAlt: "...",
                }}
                isNavbarHidden={isNavbarHidden}
              />
              <div className="main-content main_web_mobile" ref={mainContent}>
                <AdminNavbar isNavbarHidden={isNavbarHidden} />
                {!isLoading && <Route
                  {...props}
                  render={(props) => {
                    if (isAllowShow(location.pathname, userRole)) {
                      return <Component {...props} />
                    } else {
                      return (
                        <Redirect
                          exact
                          to={{
                            pathname: "/",
                            state: {
                              from: props.location,
                            },
                          }}
                        />
                      );
                    }
                  }}
                />}
              </div>
            </>
          }
        </>
      ) : (
        <div>
          <Route
            {...props}
            render={(props) => {
              if (isAllowShowMobile(location.pathname)) {
                return <>
                  {(!headerNotShown.includes(location.pathname)) && <MobileNewFooter />}
                  <Component {...props} />
                </>
              } else {
                return (
                  <Redirect
                    exact
                    to={{
                      pathname: "/app",
                      state: {
                        from: props.location,
                      },
                    }}
                  />
                );
              }
            }}
          />
        </div>
      ))}
    </>
  );
};

export default ProtectedRoute;
