import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";
import { exportToExcel } from "utils/reportGenerator";

const initialState = {
  currentPageAccounts: 1,
  pageSizeAccounts: 10,
  totalRecordAccounts: 0,
  startApiCallAccountList: false,
  sortMasterAccountArr: [],
  sortMasterBrokerageArr: [],
  sortMasterBrokerageAddArr: [],
  sortMasterPartnershipAddArr: [],
  accountViewData: {},
  accountsList: [],
  accountsLoading: false,
  isAccountUpdated: false,
  searchTextMasterAccount: "",
  accountTypeSelected: "",
  accountLoader: false
};

export const addAccount = createAsyncThunk(
  "/account/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      //user info
      let mobileInformation = {};
      let segmentArr = [];
      let accountInformation = {};

      accountInformation.accountCode = data?.accountType?.label === "Cashbook" ? "CB" : data.accountCode;
      accountInformation.CashBookAuthId = data.authority?.value
        ? parseInt(data.authority.value)
        : 0;
      accountInformation.accountName = data?.accountType?.label === "Cashbook" ? `Cashbook - ${data.authority.label}` : data.accountName.trim();
      accountInformation.password = data.password ? data.password : "";
      accountInformation.viewPassword = data.viewPassword
        ? data.viewPassword
        : "";
      accountInformation.comment = data.comment ? data.comment : ""
      accountInformation.positionHoldTime = data.positionHoldTime ? data.positionHoldTime : 0
      accountInformation.accountType = data.accountType.value;
      accountInformation.openingBalance = Number(data.openingBalance);
      accountInformation.openingBalanceType = data.openingBalanceType
        ? data.openingBalanceType
        : 3;
      accountInformation.subBrokerId = data?.subBrokerId?.value
        ? data.subBrokerId.value
        : null;
      accountInformation.partnerId =
        data.partnerId && data.partnerId !== undefined && data.accountType !== 7
          ? data.partnerId.value
          : null;
      if (moment(data.expiryDate).isBefore(data.joinDate)) {
        dispatch(
          showMessage({
            message: "Expiry date should be after start date",
          })
        );
        reject();
        return;
      }
      accountInformation.joinDate = moment(new Date(data.joinDate)).format();
      accountInformation.expiryDate = moment(
        new Date(data.expiryDate)
      ).format();
      accountInformation.brokerageSharingBySubBroker =
        data.brokerageSharingBySubBroker &&
          data.brokerageSharingBySubBroker !== null
          ? data.brokerageSharingBySubBroker.value
          : null;
      accountInformation.sharingPercentageOfPartner =
        data.sharingPercentageOfPartner
          ? Number(data.sharingPercentageOfPartner)
          : 0;
      accountInformation.brokerageSharingOfSubBroker =
        data.brokerageSharingOfSubBroker
          ? Number(data.brokerageSharingOfSubBroker)
          : 0;
      accountInformation.pnlNetSharingPercentage = data.pnlNetSharingPercentage
        ? data.pnlNetSharingPercentage
        : 0;
      accountInformation.pnlGrossSharingPercentage =
        data.pnlGrossSharingPercentage ? data.pnlGrossSharingPercentage : 0;
      accountInformation.brokerageSharingOfPartner =
        data.brokerageSharingOfPartner
          ? Number(data.brokerageSharingOfPartner)
          : 0;
       accountInformation.projectType = 1;
       accountInformation.customerSupportNumber = data.customerSupportNumber;     
      //mobile info
      if (
        data.accountType.value == 8 ||
        data.accountType.value == 4 ||
        data.accountType.value == 3 ||
        data.accountType.value == 2 ||
        data.accountType.value == 10
      ) {
        mobileInformation.isViewOnly = data.isViewOnly == "No" ? false : true;
        mobileInformation.isMAccess = data.isMAccess == "No" ? false : true;
        mobileInformation.isOrderBetweenHighLow =
          data.isOrderBetweenHighLow == "No" ? false : true;
        mobileInformation.isApplyAutoSquare =
          data.isApplyAutoSquare == "No" ? false : true;
        mobileInformation.isIntraDayAutoSquare =
          data.isIntraDayAutoSquare == "No" ? false : true;
        mobileInformation.isM2MClWithLedger =
          data.isM2MClWithLedger == "No" ? false : true;
        mobileInformation.isOnlyPositionSquareOff =
          data.isOnlyPositionSquareOff == "No" ? false : true;
        mobileInformation.positionDataType = data.positionDataType;
        mobileInformation.margin = Number(data.margin);
        mobileInformation.ledgerBalance = Number(data.ledgerBalance);
        mobileInformation.intraDayMultiple = Number(data.intraDayMultiple);
        mobileInformation.positionMultiple = Number(data.positionMultiple);
        mobileInformation.optBuyIntraDayMultiple = Number(data.optBuyIntraDayMultiple);
        mobileInformation.optBuyPositionMultiple = Number(data.optBuyPositionMultiple);
        mobileInformation.optSellIntraDayMultiple = Number(data.optSellIntraDayMultiple);
        mobileInformation.optSellPositionMultiple = Number(data.optSellPositionMultiple);
        mobileInformation.lossPercentAlert = Number(data.lossPercentAlert);
        mobileInformation.closePercentAlert = Number(data.closePercentAlert);
        data.segment.forEach((element) => {
          segmentArr.push({ segmentId: element.value });
        });
        mobileInformation.mobileInformationSegments = segmentArr;
      } else {
        mobileInformation = null;
      }
      axios
        .post("master/account/create-account", {
          createdBy: userId,
          accountInformation,
          mobileInformation: mobileInformation,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject();
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          // reject(error);
        });
    });
  }
);

export const editAccount = createAsyncThunk(
  "/account/edit",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      //user info
      let mobileInformation = {};
      let segmentArr = [];
      let accountInformation = {};

      accountInformation.accountCode = data.accountCode;
      accountInformation.accountName = data.accountName;
      accountInformation.accountType = data.accountType.value;
      accountInformation.openingBalance = Number(data.openingBalance);
      accountInformation.openingBalanceType = 3;
      accountInformation.subBrokerId =
        data.subBrokerId &&
          data.subBrokerId !== undefined &&
          data.accountType !== 6
          ? data.subBrokerId.value
          : null;
      if (moment(data.expiryDate).isBefore(data.joinDate)) {
        dispatch(
          showMessage({
            message: "Expiry date should be after start date",
          })
        );
        reject();
        return;
      }
      accountInformation.joinDate = moment(data.joinDate).format();
      accountInformation.expiryDate = moment(data.expiryDate).format();
      accountInformation.brokerageSharingBySubBroker =
        data.brokerageSharingBySubBroker &&
          data.brokerageSharingBySubBroker !== null
          ? data.brokerageSharingBySubBroker.value
          : null;
      accountInformation.sharingPercentageOfPartner =
        data.sharingPercentageOfPartner
          ? Number(data.sharingPercentageOfPartner)
          : 0;
      accountInformation.brokerageSharingOfSubBroker =
        data.brokerageSharingOfSubBroker
          ? Number(data.brokerageSharingOfSubBroker)
          : 0;
      accountInformation.pnlNetSharingPercentage = data.pnlNetSharingPercentage
        ? data.pnlNetSharingPercentage
        : 0;
      accountInformation.pnlGrossSharingPercentage =
        data.pnlGrossSharingPercentage ? data.pnlGrossSharingPercentage : 0;
      accountInformation.brokerageSharingOfPartner =
        data.brokerageSharingOfPartner
          ? Number(data.brokerageSharingOfPartner)
          : 0;
      accountInformation.password = data.password ? data.password : "";
      accountInformation.viewPassword = data.viewPassword
        ? data.viewPassword
        : "";
      accountInformation.comment = data.comment ? data.comment : ""
      accountInformation.positionHoldTime = data.positionHoldTime ? data.positionHoldTime : 0
      accountInformation.partnerId =
        data.partnerId && data.partnerId !== undefined && data.accountType !== 7
          ? data.partnerId.value
          : null;
      accountInformation.projectType = 1; 
      accountInformation.customerSupportNumber = data.customerSupportNumber;      
      //mobile info
      if (
        data.accountType.value == 8 ||
        data.accountType.value == 4 ||
        data.accountType.value == 3 ||
        data.accountType.value == 2 ||
        data.accountType.value == 10
      ) {
        mobileInformation.isViewOnly = data.isViewOnly == "No" ? false : true;
        mobileInformation.isMAccess = data.isMAccess == "No" ? false : true;
        mobileInformation.isOrderBetweenHighLow =
          data.isOrderBetweenHighLow == "No" ? false : true;
        mobileInformation.isApplyAutoSquare =
          data.isApplyAutoSquare == "No" ? false : true;
        mobileInformation.isIntraDayAutoSquare =
          data.isIntraDayAutoSquare == "No" ? false : true;
        mobileInformation.isM2MClWithLedger =
          data.isM2MClWithLedger == "No" ? false : true;
        mobileInformation.isOnlyPositionSquareOff =
          data.isOnlyPositionSquareOff == "No" ? false : true;
        mobileInformation.positionDataType = data.positionDataType;
        mobileInformation.margin = data.margin;
        mobileInformation.ledgerBalance = data.ledgerBalance;
        mobileInformation.intraDayMultiple = data.intraDayMultiple;
        mobileInformation.positionMultiple = data.positionMultiple;
        mobileInformation.optBuyIntraDayMultiple = data.optBuyIntraDayMultiple;
        mobileInformation.optBuyPositionMultiple = data.optBuyPositionMultiple;
        mobileInformation.optSellIntraDayMultiple = data.optSellIntraDayMultiple;
        mobileInformation.optSellPositionMultiple = data.optSellPositionMultiple;
        mobileInformation.lossPercentAlert = data.lossPercentAlert;
        mobileInformation.closePercentAlert = data.closePercentAlert;
        data.segment.forEach((element) => {
          segmentArr.push({ segmentId: element.value });
        });
        mobileInformation.mobileInformationSegments = segmentArr;
      } else {
        mobileInformation = null;
      }
      axios
        .post("master/account/update-account", {
          id: data.id,
          createdBy: userId,
          accountInformation,
          mobileInformation: mobileInformation,
        })
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data.message);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteAccount = createAsyncThunk(
  "/account/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      //user info
      let { id, deleted } = data;
      axios
        .delete(`master/account/delete-account/${id}/${deleted}`)
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const createInstantAccount = createAsyncThunk(
  "/account/create-instant-account",
  (data, { dispatch }) => {
    let { userId } = getSessionData();
    const obj = {
      "createdBy": userId,
      "accountName": data?.accountName,
      "accountCode": data?.accountCode,
      "password": data?.password,
      "viewPassword": data?.viewPassword,
      "fromClientId": data?.selectedUser?.value
    }
    return new Promise((resolve, reject) => {
      axios
        .post('master/account/create-instant-account', obj)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const changeMasterAccountChangeActiveStatus = createAsyncThunk(
  "/account/statuchange",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, isActive } = data;
      axios
        .post(`master/account/change-active-status?isActive=${!isActive}`, [id])
        .then(({ data }) => {
          dispatch(setStartPageAccounts(1));
          resolve({ data: data.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const changeMasterAccountLedgerActiveStatus = createAsyncThunk(
  "/account/statuchange/ledger",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, isLedgerInApp } = data;
      axios
        .post(
          `master/account/change-ledger-in-app-status?isActive=${!isLedgerInApp}`,
          [id]
        )
        .then(({ data }) => {
          dispatch(setStartPageAccounts(1));
          resolve({ data: data.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const changeMasterAccountPositionSquareOff = createAsyncThunk(
  "/account/position-square-off",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, isOnlyPositionSquareOff } = data;
      axios
        .post(
          `master/account/change-position-square-off-only?isActive=${!isOnlyPositionSquareOff}`,
          [id]
        )
        .then(({ data }) => {
          dispatch(setStartPageAccounts(1));
          resolve({ data: data.data });
        })

        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getAccountList = createAsyncThunk("/account/get", (data) => {
  return new Promise((resolve, reject) => {
    let { userId } = getSessionData();
    const getAccountObj = {
      id: userId,
      pageNo: data.pageNo,
      pageSize: data.pageSize,
      authId: data?.authId?.value ? parseInt(data.authId.value) : 0,
      roleType: data.roleType,
      searchText: data.searchText,
    };
    const { appendData } = data;
    axios
      .post("master/account/get-accounts", getAccountObj)
      .then(({ data }) => {
        resolve({ data: data.data, appendData });
      })
      .catch((error) => {
        reject(error);
      });
  });
});

export const getAccountViewData = createAsyncThunk(
  "/account/get/signle",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master/account/get-user/${data.original.id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const instantChangeActiveStatus = createAsyncThunk(
  "/account/instant-change-active-status",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get('master/account/instant-change-active-status')
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getAccountBackup = createAsyncThunk(
  "/account/get/backup",
  (userid, { dispatch }) => {
    let { userId } = getSessionData();
    return new Promise((resolve, reject) => {
      axios
        .get(`master/account-report/get-account-backup/${userId}`)
        .then(({ data }) => {
          if (data.status === 200 && data?.data?.length > 0) {
            let allAcountBackupData = [];
            data.data.forEach((item) => {
              allAcountBackupData = [...allAcountBackupData, ...item.data];
            });
            exportToExcel({
              apiData: allAcountBackupData,
              fileName: "Account_Backup",
            });
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const masterSlice = createSlice({
  name: "marketWatch",
  initialState,
  reducers: {
    setSortMasterAccount: (state, action) => {
      state.sortMasterAccountArr = action.payload;
    },
    setAccountViewData: (state, action) => {
      state.accountViewData = action.payload;
    },
    setAccountUpdated: (state, action) => {
      state.isAccountUpdated = action.payload;
    },
    setSearchTextMasterAccounts: (state, action) => {
      state.searchTextMasterAccount = action.payload;
    },
    setStartPageAccounts: (state, action) => {
      state.currentPageAccounts = action.payload;
    },
    setStartApiCallAccountsList: (state, action) => {
      state.startApiCallAccountList = action.payload;
    },
    setTotalRecordAccounts: (state, action) => {
      state.totalRecordAccounts = action.payload;
    },
    setAccountTypeSelected: (state, action) => {
      state.accountTypeSelected = action.payload;
    },
    setAccountsList: (state, action) => {
      state.accountsList = action.payload;
    },
    setAccountsLoading: (state, action) => {
      state.accountsLoading = action.payload;
    },
  },
  extraReducers: {
    [getAccountBackup.pending]: (state, action) => {
      state.accountsLoading = true;
    },
    [getAccountBackup.rejected]: (state, action) => {
      state.accountsLoading = false;
    },
    [getAccountBackup.fulfilled]: (state, action) => {
      state.accountsLoading = false;
    },
    [addAccount.pending]: (state, action) => {
      state.accountsLoading = true;
      state.isAccountUpdated = false;
    },
    [addAccount.rejected]: (state, action) => {
      state.accountsLoading = false;
      state.isAccountUpdated = false;
    },
    [addAccount.fulfilled]: (state, action) => {
      state.accountsLoading = false;
      state.isAccountUpdated = true;
    },
    [createInstantAccount.pending]: (state, action) => {
      state.accountsLoading = true;
      state.isAccountUpdated = false;
    },
    [createInstantAccount.rejected]: (state, action) => {
      state.accountsLoading = false;
      state.isAccountUpdated = false;
    },
    [createInstantAccount.fulfilled]: (state, action) => {
      state.accountsLoading = false;
      state.isAccountUpdated = true;
    },
    [editAccount.pending]: (state, action) => {
      state.isAccountUpdated = false;
    },
    [editAccount.rejected]: (state, action) => {
      state.isAccountUpdated = false;
    },
    [editAccount.fulfilled]: (state, action) => {
      state.isAccountUpdated = true;
    },
    [getAccountList.pending]: (state, action) => {
      state.accountsLoading = true;
    },
    [getAccountList.rejected]: (state, action) => {
      state.accountsList = [];
      state.totalRecordAccounts = 0;
      state.accountsLoading = false;
    },
    [getAccountList.fulfilled]: (state, action) => {
      state.totalRecordAccounts = action.payload.data.totalRecords;
      if (action.payload.appendData) {
        state.accountsList = [
          ...state.accountsList,
          ...action.payload.data.records,
        ];
      } else {
        state.accountsList = action.payload.data.records;
      }
      state.accountsLoading = false;
    },

    //account status chnage
    [changeMasterAccountChangeActiveStatus.pending]: (state, action) => {
      state.accountLoader = true
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountChangeActiveStatus.rejected]: (state, action) => {
      state.accountLoader = false
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountChangeActiveStatus.fulfilled]: (state, action) => {
      state.accountLoader = false
      state.startApiCallAccountList = true;
    },
    [changeMasterAccountPositionSquareOff.pending]: (state, action) => {
      state.accountLoader = true
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountPositionSquareOff.rejected]: (state, action) => {
      state.accountLoader = false
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountPositionSquareOff.fulfilled]: (state, action) => {
      state.accountLoader = false
      state.startApiCallAccountList = true;
    },
    [changeMasterAccountLedgerActiveStatus.pending]: (state, action) => {
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountLedgerActiveStatus.rejected]: (state, action) => {
      state.startApiCallAccountList = false;
    },
    [changeMasterAccountLedgerActiveStatus.fulfilled]: (state, action) => {
      state.startApiCallAccountList = true;
    },

    [getAccountViewData.pending]: (state, action) => {
      state.accountViewData = {};
    },
    [getAccountViewData.rejected]: (state, action) => {
      state.accountViewData = {};
    },
    [getAccountViewData.fulfilled]: (state, action) => {
      state.accountViewData = action.payload;
    },

    [deleteAccount.pending]: (state, action) => {
      state.isAccountUpdated = false;
    },
    [deleteAccount.rejected]: (state, action) => {
      state.isAccountUpdated = false;
    },
    [deleteAccount.fulfilled]: (state, action) => {
      state.isAccountUpdated = true;
    },
  },
});

export const {
  setSortMasterAccount,
  setAccountViewData,
  setAccountUpdated,
  setSearchTextMasterAccounts,
  setStartPageAccounts,
  setStartApiCallAccountsList,
  setTotalRecordAccounts,
  setAccountTypeSelected,
  setAccountsList,
  setAccountsLoading
} = masterSlice.actions;

export default masterSlice.reducer;
