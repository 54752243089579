import { components } from "react-select";

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="material-icons-outlined fs-20">keyboard_arrow_down</i>
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <i className="material-icons-outlined mr-n3">close</i>
    </components.ClearIndicator>
  );
};

export const stylesSelect = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "39px",
    fontSize: "0.875rem",
    color: "#666",
    border: "1px solid #cad1d7",
    border: state.isFocused ? "1px solid #885b24" : "",
    backgroundColor: state.isFocused ? "#e1ebff" : "white",
    boxShadow: state.isFocused ? "#885b24" : "#885b24",
    "&:hover": {
      borderColor: "#885b24",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "",
  }),
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      display: "none",
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: state.isSelected ? "#fff" : state.isFocused ? "#666" : "#666",
    /* state.isSelected
      ? "#ffffff"
      : state.isSelected && state.isFocused
      ? "#ffffff"
      : !state.isSelected && state.isFocused
      ? "#f00"
      : "#666", */
    /* state.isSelected
      ? "#ffffff"
      : state.isSelected && state.isFocused
      ? "#ffffff"
      : !state.isSelected && state.isFocused
      ? "#ffffff"
      : "#666", */
    backgroundColor: state.isSelected
      ? "#885b24"
      : state.isFocused
      ? "#d9d9d9"
      : "#ffffff",
    ":active": {
      backgroundColor: "#885b24",
      color: "#fff",
    },
  }),
};

export const stylesSelectAlternative = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "43px",
    fontSize: "0.875rem",
    color: "#adb5bd",
    border: "0",
    boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "",
  }),
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
    };
  },
  indicatorSeparator: (provided, state) => {
    return {
      ...provided,
      display: "none",
    };
  },
  singleValue: (provided, state) => {
    return {
      ...provided,
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused || state.isSelected ? "#ffffff" : "#32325d",
    backgroundColor:
      state.isFocused || state.isSelected ? "#885b24" : "#ffffff",
  }),
};
