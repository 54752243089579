import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import "../../assets/scss/mobile/newStyleMobile.scss";
import { Link, useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import { socket } from "App";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  setOpenProfileModal,
  setSocketData,
  setFilteredMarketWatchList,
  getMarketWatchList,
  setAddScriptFormValues,
  setMarketWatchList,
  setSelectedScript,
  setSearchTextMarketWatch,
  setMultipleSelectedScriptData,
  setOldSymbolNameArray,
  setSymbolNameArray,
  setScriptIndexList,
  multipleDeleteMarketWatch
} from "store/reducers/marketWatch.slice";
import { useSelector } from "react-redux";
import MarketWatchIndexShow from './MarketWatchIndexShow';
import FilterMarketWatchData from './FilterMarketWatchData';
import { getUrlParam } from "utils/common";
import { getSegmentOptionList, getNotificationList, getSymbolListForNse, setSymbolList } from "store/reducers/common.slice";
import MobileNewMarketWatchTable from "./MobileNewMarketWatchTable";
import deleteIcon from '../../assets/img/icons/delete.svg'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import MobileBuySellModal from "./MobileBuySellModal";
export default function MobileNewDashboard({ isSocketCall }) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  let timerRef = useRef();
  const history = useHistory()
  const { segmentsList = [] } = useSelector(({ common }) => common);
  const {
    searchTextMarketWatch,
    liveRateFromSocket,
    marketWatchList = new Map(),
    filteredMarketWatchList = new Map(),
    selectedScript,
    addScriptFormValues,
    scriptIndexList = new Map(),
    openProfileModal,
    oldSymbolNameArray,
    symbolNameArray,
    multipleSelectedScriptData
  } = useSelector(({ marketWatch }) => marketWatch);
  const { marketType = {} } = addScriptFormValues || {};
  const { userRole } = useSelector(({ auth }) => auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [channelType, setChannelType] = useState("");
  const [isOpenBuySellModal, setIsOpenBuySellModal] = useState(false);
  const [expandMore, setExpandMore] = useState(false);
  const [sortObj, setSortObj] = useState({ "id": "script", desc: false, });
  const [filterValue, setFilterValue] = useState({ "id": "script", desc: false, })
  const [isDeleteActive, setIsDeleteActive] = useState(false)
  const getInitSegment = useCallback((segmentList = []) => {
    let segment;
    const currentTabId = getUrlParam(window.location.search, "tab_id");
    if (currentTabId)
      segment = segmentList.find((x) => x.value === Number(currentTabId));
    else
      segment = segmentList.find((x) => {
        let item = "";
        const { label } = x || {};
        if (label === "SGXNIFTY") item = x;
        else if (label === "NSEFUT") item = x;
        else if (label === "MCXFUT") item = x;
        else if (label === "NSEOPT") item = x;
        else if (label === "NSECD") item = x;
        return item;
      });
    return segment;
  }, []);
  const sortList = useCallback((array = [], sortObj) => {
    let sorted = [];
    let list = array || [];
    if (sortObj) {
      const descAscOrder = sortObj?.desc ? ["desc"] : ["asc"];
      if (sortObj?.id === "changePercent" || sortObj?.id === "changeAmount") {
        list = _.sortBy(list, [sortObj?.id], descAscOrder);
      } else {
        list = _.orderBy(list, [sortObj?.id], descAscOrder);
      }
    }
    const defaultScripts = ["nifty", "banknifty", "gold"];
    list && list?.forEach((x) => {
      if (defaultScripts.includes(x?.symbol?.toLowerCase())) sorted.unshift(x);
      else sorted.push(x);
    });
    return sorted;
  }, []);
  const onFilterMarketWatchList = useCallback(
    (watchList, searchQuery) => {
      let list = watchList || [];
      const currentSegment = getUrlParam(window.location.search, "tab_id");
      if (list && list.length > 0) {
        list = list?.filter((x) => x.segmentId === Number(currentSegment || 1));
      }
      if (searchQuery) {
        list = list.filter((x) => {
          return (
            x?.script?.trim()?.toLowerCase()?.includes(searchQuery) ||
            x?.displayName?.trim()?.toLowerCase()?.includes(searchQuery) ||
            x?.symbol?.trim()?.toLowerCase()?.includes(searchQuery)
          );
        });
      }
      const sortedListData = sortList(list, sortObj)
      if (sortedListData?.length > 0 && oldSymbolNameArray?.length === 0) {
        let symbolNameArrayData = sortedListData.slice(0 + (currentPage * 20 - 20), currentPage * 20).map(item => item.displayName)
        dispatch(setSymbolNameArray(symbolNameArrayData))
        dispatch(setOldSymbolNameArray(symbolNameArrayData))
      }
      if (sortedListData?.length > 0 && oldSymbolNameArray?.length > 0) {
        dispatch(setOldSymbolNameArray(symbolNameArray))
        let symbolNameArrayData = sortedListData.slice(0 + (currentPage * 20 - 20), currentPage * 20).map(item => item.displayName)
        dispatch(setSymbolNameArray(symbolNameArrayData))
      }
      if (sortedListData?.length === 0) {
        dispatch(setSymbolNameArray([]))
      }
      dispatch(setFilteredMarketWatchList(sortedListData));
    },
    [dispatch, sortList, sortObj]
  );
  const onRequestSort = useCallback(
    (object) => {
      if (sortObj) {
        if (sortObj?.id === object.key) {
          if (sortObj?.desc === false) object.desc = true;
          else if (sortObj?.desc === true) object = undefined;
        }
      }
      setSortObj(object);
      localStorage.setItem("sortObj", JSON.stringify(object || null));
      const sortedList = sortList(
        [...filteredMarketWatchList?.values()],
        object
      );
      dispatch(setFilteredMarketWatchList(sortedList));
      dispatch(setOpenProfileModal(false));
    },
    [dispatch, sortList, sortObj, filteredMarketWatchList]
  );

  const loadData = useCallback(async () => {
    const { payload: watchList } = await dispatch(getMarketWatchList({ isRefresh: true }));
    const { payload: segmentList } = await dispatch(getSegmentOptionList());
    const segment = getInitSegment(segmentList);
    dispatch(getSymbolListForNse({ segmentId: segment.value }));
    history.push(`${window.location.pathname}?tab_id=${segment.value}`);
    setCurrentPage(1);
    dispatch(setAddScriptFormValues({ marketType: segment }));
    onFilterMarketWatchList(watchList, searchTextMarketWatch);
    await dispatch(getNotificationList());
  }, [
    history,
    dispatch,
    getInitSegment,
    onFilterMarketWatchList,
    searchTextMarketWatch,
  ]);
  useEffect(() => {
    loadData();
  }, [dispatch, loadData]);
  useEffect(() => {
    socket.on('message', (data) => {
      if (Object.keys(data).length > 0) {
        dispatch(setSocketData(data));
      }
    });
    socket.on('disconnect', () => {
      socket.emit('room', symbolNameArray.toString())
    });
  }, [])
  useEffect(() => {
    if (socket?.connected && symbolNameArray) {
      if (symbolNameArray?.length > 0) {
        if (oldSymbolNameArray?.length > 0) {
          socket.emit('leave_room', oldSymbolNameArray.toString())
        }
        socket.emit('room', symbolNameArray.toString())
      } else if (symbolNameArray?.length === 0 && oldSymbolNameArray?.length > 0) {
        socket.emit('leave_room', oldSymbolNameArray.toString())
        dispatch(setOldSymbolNameArray([]))
      }
    }
    return () => {
      if (window.location.pathname !== "/app" && symbolNameArray?.length > 0) {
        dispatch(setSymbolNameArray([]))
        dispatch(setSymbolList([]))
        socket.emit('leave_room', symbolNameArray.toString())
        dispatch(setOldSymbolNameArray([]))
        dispatch(setSearchTextMarketWatch(''))
      }
    }
  }, [dispatch, isSocketCall, symbolNameArray]);

  useEffect(() => {
    if (isSocketCall) {
      const { Script, LTP, Bid, Ask, PrevClose, Low, High, Open, LTTime } =
        liveRateFromSocket || {};
      let temp = { ...filteredMarketWatchList.get(Script) };
      if (liveRateFromSocket && scriptIndexList.size > 0) {
        let indexTemp = { ...scriptIndexList.get(Script) };
        if (indexTemp) {
          const { lastTradedPrice } = indexTemp || {};
          indexTemp.colorLTP =
            lastTradedPrice > LTP
              ? "#BF2114"
              : lastTradedPrice < LTP
                ? "#0000CD"
                : "#000";
          indexTemp.lastTradedPrice = (Number(LTP)).toFixed(2);
          indexTemp.changeAmountColor =
            indexTemp.changeAmount > 0 ? "text-success" : "text-danger";
          indexTemp.changeAmount = (LTP - PrevClose).toFixed(2);
          indexTemp.changePercent =
            LTP && PrevClose ? (((LTP - PrevClose) / LTP) * 100).toFixed(2) : 0;
          const scriptIndexMap = new Map(scriptIndexList);
          scriptIndexMap.set(Script, indexTemp);
          dispatch(setScriptIndexList(scriptIndexMap));
        }
      }
      if (temp) {
        const {
          displayName,
          segmentId,
          lastTradedPrice,
          buyPrice,
          sellPrice,
          isUpdated,
        } = temp || {};
        if (Script === displayName) {
          const isAvailableInCurrentPage = currentData?.findIndex(
            (x) => x.displayName === displayName
          );
          if (!isUpdated || (isUpdated && isAvailableInCurrentPage !== -1)) {
            if (LTTime) {
              const getCurrentTime = () => {
                const now = new Date();
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');
                return `${hours}:${minutes}:${seconds}`;
              }
              if (segmentId === 5) {
                var date = new Date(LTTime * 1000);
                temp.ltTime = date ? `${date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours()}:${date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes()}:${date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : date.getUTCSeconds()}` : '00:00:00'
                temp.ltTime2 = temp.ltTime2 ? (lastTradedPrice && LTP) ? lastTradedPrice !== LTP ? getCurrentTime() : temp.ltTime2 : '00:00:00' : '00:00:00'
              } else {
                if (LTTime?.length === 8) {
                  temp.ltTime = LTTime ? `${LTTime.slice(0, 1)}:${LTTime.slice(1, 3)}:${LTTime.slice(3, 5)}` : '00:00:00'
                } else {
                  temp.ltTime = LTTime ? `${LTTime.slice(0, 2)}:${LTTime.slice(2, 4)}:${LTTime.slice(4, 6)}` : '00:00:00'
                }
                temp.ltTime2 = temp.ltTime2 ? (lastTradedPrice && LTP) ? lastTradedPrice !== LTP ? getCurrentTime() : temp.ltTime2 : '00:00:00' : '00:00:00'
              }
            } else {
              temp.ltTime = '00:00:00'
            }
            temp.isUpdated = true;
            temp.segmentId = segmentId;
            temp.lastTradedPrice = (Number(LTP)).toFixed(2);
            temp.buyPrice = Number(Bid).toFixed(2)

            temp.sellPrice = Number(Ask).toFixed(2)
            temp.colorLTP =
              lastTradedPrice > LTP
                ? "#FF1A2D"
                : lastTradedPrice < LTP
                  ? "#009846"
                  : "#b6884d";
            temp.colorBid =
              buyPrice > Bid ? "#FF1A2D" : buyPrice < Bid ? "#009846" : "#b6884d";
            temp.colorAsk =
              sellPrice > Ask ? "#FF1A2D" : sellPrice < Ask ? "#009846" : "#b6884d";
            let closePrice = PrevClose;
            temp.close = (Number(closePrice)).toFixed(2);
            temp.low = (Number(Low)).toFixed(2);
            temp.high = (Number(High)).toFixed(2);
            temp.open = Open;
            temp.changeAmount = LTP - closePrice;
            temp.changeAmountColor =
              temp.changeAmount > 0 ? "text-success" : "text-danger";
            temp.changePercent =
              LTP && closePrice
                ? (((LTP - closePrice) / LTP) * 100)
                : 0;
            const filteredMap = new Map(filteredMarketWatchList);
            const map = new Map(marketWatchList);
            filteredMap.set(Script, temp);
            map.set(Script, temp);
            dispatch(setFilteredMarketWatchList(filteredMap));
            dispatch(setMarketWatchList(map));
            if (
              selectedScript &&
              Object.keys(selectedScript).length > 0 &&
              selectedScript?.displayName === Script
            ) {
              dispatch(setSelectedScript(temp));
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, liveRateFromSocket, userRole, isOpenBuySellModal]);
  const handleSearchChange = useCallback(
    (e) => {
      let searchText = e.target.value;
      if (searchText) searchText = searchText.trim().toLowerCase();
      dispatch(setSearchTextMarketWatch(searchText));
      dispatch(setSelectedScript({}));
      onFilterMarketWatchList([...marketWatchList.values()], searchText);
    },
    [dispatch, onFilterMarketWatchList, marketWatchList]
  );
  const debounceHandleSearchTextChange = useCallback(
    (e) => {
      if (timerRef?.current) clearTimeout(timerRef?.current);
      timerRef.current = setTimeout(() => {
        handleSearchChange(e);
      }, 800);
    },
    [handleSearchChange]
  );

  const onChangeTab = useCallback(
    (segment) => {
      history.push(`${window.location.pathname}?tab_id=${segment?.value}`);
      dispatch(setAddScriptFormValues({ marketType: segment }));
      loadData();
    },
    [dispatch, loadData, history]
  );
  const currentData = useMemo(() => {
    const currentDataList = [...filteredMarketWatchList.values()]
      ?.slice(0 + (currentPage * 20 - 20), currentPage * 20)
      ?.filter((x) => x?.segmentId);
    if (
      currentDataList.length > 0 &&
      selectedScript &&
      Object.keys(selectedScript).length === 0
    ) {
      dispatch(setSelectedScript(currentDataList[0]));
    }
    return currentDataList;
  }, [filteredMarketWatchList, currentPage]);

  const onCloseBuySellModal = useCallback(() => {
    setIsOpenBuySellModal(false);
    dispatch(setSelectedScript({}));
  }, [dispatch]);

  const onPageChange = useCallback(
    (page) => {
      let pageIndex = currentPage;
      if (page === "Prev") pageIndex--;
      else if (page === "Next") pageIndex++;
      else pageIndex = page;
      setCurrentPage(pageIndex);
      dispatch(setSelectedScript({}));
      const newCurrentDataList = [...filteredMarketWatchList.values()]
        ?.slice(0 + (pageIndex * 20 - 20), pageIndex * 20)
        ?.filter((x) => x?.segmentId);
      if (newCurrentDataList?.length > 0 && oldSymbolNameArray?.length === 0) {
        let symbolNameArrayData = newCurrentDataList.slice(0, 20).map(item => item.displayName)
        dispatch(setSymbolNameArray(symbolNameArrayData))
        dispatch(setOldSymbolNameArray(symbolNameArrayData))
      }
      if (newCurrentDataList?.length > 0 && oldSymbolNameArray?.length > 0) {
        dispatch(setOldSymbolNameArray(symbolNameArray))
        let symbolNameArrayData = newCurrentDataList.slice(0, 20).map(item => item.displayName)
        dispatch(setSymbolNameArray(symbolNameArrayData))
      }
      /* const sortedListData = sortList([...filteredMarketWatchList.values()], sortObj)
      console.log('sortedListData', sortedListData) */
    },
    [dispatch, currentPage, filteredMarketWatchList]
  );

  const renderPageIndexes = useMemo(() => {
    const pages = [];
    let totalData = filteredMarketWatchList?.size || 0;
    if (totalData > 20) {
      let pageNo = 1;
      while (totalData > 0) {
        pages.push(pageNo);
        totalData -= 20;
        pageNo += 1;
      }
      if (currentPage > 1) pages.unshift("Prev");
      if (currentPage < pageNo - 1) pages.push("Next");
    }
    if (pages?.length === 0) return null;
    else
      return (
        <div className="col-12 mb-2">
          <div className="row g-2">
            <div className="col-12">
              <div className={`not-default pagination_wrap`}>
                <ul>
                  {pages?.map((page, i) => {
                    return (
                      <li
                        key={`page_${i}`}
                        className={page === currentPage ? "active" : ""}
                        onClick={() => {
                          onPageChange(page);
                        }}
                      >
                        {page}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
  }, [
    filteredMarketWatchList?.size,
    currentPage,
    onPageChange,
  ]);
  const onSetScriptItem = useCallback(
    (index) => {
      if (index !== -1) {
        const selectedItem = currentData[index];
        if (
          Object.keys(selectedItem).length > 0 &&
          selectedItem.buyPrice &&
          selectedItem.sellPrice
        ) {
          dispatch(setSelectedScript(selectedItem));
          setIsOpenBuySellModal(true);
        }
      }
    },
    [dispatch, currentData]
  );
  const checkBoxHandler = useCallback(
    (row) => {
      const newFilteredMarketWatchList = new Map(filteredMarketWatchList);
      newFilteredMarketWatchList.set(row.displayName, {
        ...row,
        isCheck: !row.isCheck,
      });
      const selectPositionReport = _.filter(
        [...newFilteredMarketWatchList.values()],
        (item) => {
          if (item.isCheck) {
            return item;
          }
        }
      );
      dispatch(dispatch(setMultipleSelectedScriptData(selectPositionReport)));
      dispatch(setFilteredMarketWatchList(newFilteredMarketWatchList));
    },
    [dispatch, filteredMarketWatchList]
  );

  return (
    <>
      <div className="mdashboard_wrap" ref={ref}>
        <header>
          <div className="mheader_wrap">
            <div className="toggle_arrow_wrap">
              <button
                type="button"
                className={expandMore ? "trans_btn show" : "trans_btn"}
                onClick={() => setExpandMore(!expandMore)}
              >
                <span className="material-symbols-outlined text-white">
                  expand_more
                </span>
              </button>
            </div>
            <MarketWatchIndexShow expandMore={expandMore} scriptIndexList={scriptIndexList} filteredMarketWatchList={filteredMarketWatchList} />
            <marquee width="100%" direction="left">
              <h5 className="m-0">
                No real money involved in this ID (App). This is a Virtual Trading Application which has all the features to trade. This application is used for exchanging views on markets for individual students for training purpose only.
              </h5>
            </marquee>
            <div className="search_main_wrap">
              <div className="search_mobile flex-grow-1">
                <div className="searchBar">
                  <span className="material-symbols-outlined text-white">
                    search
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Script"
                    onChange={debounceHandleSearchTextChange}
                  />
                </div>
              </div>
              <ul>
                <li>
                  <span
                    className="material-symbols-outlined up_down_icon text-white"
                    onClick={() =>
                      setTimeout(() => {
                        dispatch(setOpenProfileModal(!openProfileModal));
                      }, 100)
                    }
                  >
                    swap_vert
                  </span>
                </li>
                <li>
                  <Link to="/app/add-script">
                    <span className="material-symbols-outlined plus_icon text-white">
                      add
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mtop_tab_wrap">
              <ul>
                {segmentsList?.map((segment, i) => {
                  return (
                    <li
                      key={`segment_${i}`}
                      className={marketType?.label === segment?.label ? 'active' : ""}
                      onClick={() => {
                        if (marketType?.label !== segment?.label) {
                          onChangeTab(segment);
                        }
                      }}
                    >
                      {segment?.label}
                    </li>
                  );
                })}
              </ul>
              <div className="edit_icon_wrap  d-flex">
                <UncontrolledDropdown nav className="mb-0 mt-0 tv_dropdown_wrapper tv_mobile_wrapper">
                  <DropdownToggle
                    nav
                    className="nav-link-icon position-relative tv_dropdown"
                  >
                    <span className="material-symbols-outlined">desktop_windows</span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem onClick={() => setChannelType("english")}>
                      English Channel
                    </DropdownItem>
                    <DropdownItem onClick={() => setChannelType("hindi")}>
                      Hindi Channel
                    </DropdownItem>
                    <DropdownItem onClick={() => setChannelType("gujarati")}>
                      Gujarati Channel
                    </DropdownItem>
                  </DropdownMenu>
                  <div
                    className={channelType ? "tv_video_box show" : "tv_video_box"}
                  >
                    <div
                      className="video_close_icon"
                      onClick={() => setChannelType("")}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </div>
                    {channelType === "english" && (
                      <iframe
                        id="vidgyor_iframe"
                        frameborder="0"
                        src="https://www.youtube.com/embed/P857H4ej-MQ"
                        mozallowfullscreen=""
                        webkitallowfullscreen=""
                        scrolling="no"
                        height="150px"
                        width="300px"
                        allowFullScreen
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    )}
                    {channelType === "hindi" && (
                      <iframe
                        id="vidgyor_iframe"
                        frameborder="0"
                        src="https://www.youtube.com/embed/TD0A7fHAxKw"
                        mozallowfullscreen=""
                        webkitallowfullscreen=""
                        scrolling="no"
                        height="150px"
                        width="300px"
                        allowFullScreen
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    )}
                    {channelType === "gujarati" && (
                      <iframe
                        id="vidgyor_iframe"
                        frameborder="0"
                        src="https://www.youtube.com/embed/LziO-1JrvQo"
                        mozallowfullscreen=""
                        webkitallowfullscreen=""
                        scrolling="no"
                        height="150px"
                        width="300px"
                        allowFullScreen
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      ></iframe>
                    )}
                  </div>
                </UncontrolledDropdown>
                <button type="button" className="trans_btn" onClick={() => setIsDeleteActive(true)}>
                  <span>
                    <i className="material-icons-outlined text-white">edit</i>
                  </span>
                </button>
                {isDeleteActive && <div className="btn_side_wrap">
                  <ul>
                    <li>
                      <button type="button" className="trans_btn close_btn" onClick={() => setIsDeleteActive(false)}>
                        <span>
                          <i className="material-icons-outlined text-white">close</i>
                        </span>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="trans_btn delete_icon" onClick={() =>
                        Swal.fire({
                          title: "Are you sure?",
                          text: `Are you sure to delete this script?`,
                          icon: "question",
                          showCancelButton: true,
                          confirmButtonColor: "#885b24",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, Execute it!",
                          width: 400,
                          height: 400,
                        }).then((result) => {
                          if (result.isConfirmed) {
                            dispatch(
                              multipleDeleteMarketWatch(
                                multipleSelectedScriptData
                              )
                            );
                          }
                        })
                      }
                        disabled={multipleSelectedScriptData.length === 0 ? 'disabled' : ''}>
                        <img src={deleteIcon} alt="pdficon" className="delete_icon" />
                      </button>
                    </li>
                  </ul>
                </div>}
              </div>
            </div>
          </div>
        </header>
        <div
          className={
            expandMore ? "mdashboard_inner_wrap show" : "mdashboard_inner_wrap"
          }
        >
          <MobileNewMarketWatchTable
            currentData={currentData}
            isDeleteActive={isDeleteActive}
            onClickScript={onSetScriptItem}
            checkBoxHandler={checkBoxHandler}
          />
        </div>
        {renderPageIndexes}
      </div >
      <FilterMarketWatchData openProfileModal={openProfileModal} filterValue={filterValue} setFilterValue={setFilterValue} onSubmitFilterModal={onRequestSort} />
      <Modal
        className="modal-dialog-centered custom_model buysell_model"
        centered
        style={{ zIndex: 999999 }}
        isOpen={isOpenBuySellModal}
        toggle={onCloseBuySellModal}
      >
        {isOpenBuySellModal && (
          <MobileBuySellModal onClose={onCloseBuySellModal} />
        )}
      </Modal>
    </>
  );
}
