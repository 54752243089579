import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import { showMessage } from "../common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  quantityList: [],
  quantityLoading: false,
  isQuantitySettingUpdated: false,
  isQuantityGet: false,
  quantitySettingFilterValue: {},
  cepeSettingLoading: false,
  isCepeSettingUpdated: false,
  cepeSettingList: [],
  totalRecordsCepeSettings: 0,
  currentPageCepeSettings: 1,
  searchTextCepeSettings: '',
  cepeSettingsObj: {},
  editSettingsObj: {},
  startApiCallQuantitySettingList: true,
  totalRecordQuantitySettings: 0,
  currentPageQuantitySettings: 1,
  pageSizeQuantitySettings: 10,
  searchTextQuantitySettings: "",
  selectedAccountInQuantitySetting: "",
  accountSettingType: 1,
  startPageQuantitySetting: 1,
  transferAddSuccess: false,
  transferAddLoading: false,
  blockAllowScriptsList: [],
  totalRecordBlockScriptSettings: 0,
  blockScriptList: [],
  blockScriptLoading: false,
  startApiCallBlockScriptSettingList: false,
  isBlockScriptSettingUpdated: false,
  searchTextBlockScriptSettings: "",
  sortSettingExpiryArr: [],
  settingSymbolExpirysList: [],
  isSymbolExpirySettingUpdated: false,
  symbolExpiryLoading: false,
  currentPageSymbolExpirySettings: 1,
  searchTextSymbolExpirySettings: "",
  pageSizeSymbolExpirySettings: 10,
  totalRecordSymbolExpirySettings: 0,
  startApiCallSymbolExpirySettingList: false,
  selectedSegmentInExpirySetting: "",
  expirySettingDeleteId: "",
  symbolsExpirySymbolList: [],
  symbolDetailForQtySetting: {},
  symbolOffQuoteList: [],
  isSymbolOffQuoteUpdated: false,
  symbolOffQuoteLoading: false,
  searchTextSymbolOffQuote: '',
  qtySettingFromMasterAcc: {}
};

//Quantity
export const addQuentitySetting = createAsyncThunk(
  "/setting/quantity-setting/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.symbol = paramData.symbol.value === "SEGMENT" ? null : paramData.symbol.value;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.userId = paramData.userId.value;
      newFinalObject.rateStart = Number(paramData.rateStart);
      newFinalObject.rateEnd = Number(paramData.rateEnd);
      newFinalObject.positionLimit = paramData.positionLimit;
      newFinalObject.maxOrder = paramData.maxOrder;
      newFinalObject.minOrder = paramData.minOrder;
      newFinalObject.optPerStrike = paramData.optPerStrike;
      if (!paramData.isRange) {
        newFinalObject.rateStart = 0;
        newFinalObject.rateEnd = 0;
      }
      newFinalObject.isRange = paramData.isRange;
      newFinalObject.accountSettingType = paramData.accountSettingType;
      newFinalObject.settingType = Number(paramData.settingType);
      newFinalObject.stopOutLevelType = paramData.stopOutLevelType
      newFinalObject.stopOutLevelAmount = paramData.stopOutLevelAmount
      axios
        .post(`settings/quantity-setting/save-quantity-setting`, newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getQuantityList = createAsyncThunk(
  "/setting/quantitysetting/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, segmentId, pageNo, pageSize, appendData, userId } = data;
      axios
        .get(
          `settings/quantity-setting/get-quantity-settings-by-user/${userId}/${segmentId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getSymbolOffQuoteList = createAsyncThunk(
  "symbol-offQuote-get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, segmentId, pageNo, pageSize, appendData } = data;
      axios
        .get(`/settings/symbol-offQuote/get-symbol-offquote-by-segment/${segmentId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""}`)
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const createSymbolOffQuote = createAsyncThunk(
  "/setting/symbol-offQuote/create",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { userId } = getSessionData()
      const obj = {
        createdBy: userId,
        id: data.id,
        symbol: data.symbol,
        segmentId: data.segment,
        symbolMultiple: data.segment!=3?data.symbolMultiple:0,
        optBuySymbolMultiple: data.segment==3?data.optBuySymbolMultiple:0,
        optSellSymbolMultiple: data.segment==3?data.optSellSymbolMultiple:0,
        second: data.time,
        stopOutLevelType: data.stopOutLevelType,
        stopOutLevelAmount: data.stopOutLevelAmount
      }
      axios
        .post(
          'settings/symbol-offQuote/save-symbol-offQuote', obj
        )
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const deleteSymbolOffQuote = createAsyncThunk(
  "/setting/symbol-offQuote/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          'settings/symbol-offQuote/delete-symbol-offQuote', data
        )
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getQuantityDetail = createAsyncThunk(
  "/setting/quantitysetting/get-detail",
  (props, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let { searchText, segmentId, pageNo, pageSize, Symbol } = props;
      let userAccountId = props?.userId ? props.userId : userId
      let param = ''
      if (segmentId && Symbol && searchText) {
        param = `?segmentId=${segmentId}&Symbol=${Symbol}&searchText=${searchText}`
      } else if (segmentId && Symbol) {
        param = `?segmentId=${segmentId}&Symbol=${Symbol}`
      }
      axios
        .get(
          `settings/quantity-setting/get-quantity-settings-details/${userAccountId}/${pageNo}/${pageSize}${param}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getCepeSettings = createAsyncThunk(
  "/setting/cepesettings/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, userId } = data;
      axios
        .get(
          `settings/CEPESettings/get-CEPE-Settings/${userId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getCepeSettingsScript = createAsyncThunk(
  "/setting/cepesettings/get-script",
  (script, { dispatch }) => {
    return new Promise((resolve, reject) => {
      const { userId } = getSessionData()
      axios
        .get(`settings/CEPESettings/check-CEPE-Settings-by-id/${userId}/${script}`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const createCepeSettings = createAsyncThunk(
  "/setting/cepesettings/create",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          'settings/CEPESettings/save-CEPE-Settings', data
        )
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const deleteCepeSettings = createAsyncThunk(
  "/setting/cepesettings/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('settings/CEPESettings/delete-CEPE-Settings', { data: data })
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteQuantitySetting = createAsyncThunk(
  "/setting/quantity_delete_setting",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("settings/quantity-setting/delete-quantity-setting", data)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

//Trasfer
export const addTrasferSetting = createAsyncThunk(
  "/setting/trasfer",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let finalObject = {};
      finalObject.createdBy = userId;
      finalObject.fromUserId = data.fromUserId.value;
      finalObject.toUserId = data.toUserId.map((item) => item.value);
      finalObject.segmentId = data.segmentId.value;
      finalObject.transferSettingsType = data.trasferType.value;
      axios
        .post(`settings/transfer-setting/save-transfer-settings`, finalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

//Block symbol

export const getBlockSymbolList = createAsyncThunk(
  "/setting/blockscript/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData } = data;
      let { userId } = getSessionData();
      axios
        .get(
          `settings/block-script/get-block-symbols-by-user/${userId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const addBlockSymbolSetting = createAsyncThunk(
  "/setting/quantitysetting/add",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = paramData.id;
      newFinalObject.createdBy = userId;
      newFinalObject.symbol = paramData.symbol.value;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.startDate = moment(paramData.fromDate)
        .tz("Asia/Kolkata")
        .format();
      newFinalObject.endDate = moment(paramData.toDate)
        .tz("Asia/Kolkata")
        .format();
      axios
        .post(`settings/block-script/save-block-symbol`, newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteBlockScriptSetting = createAsyncThunk(
  "/setting/quantity delete setting",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`settings/block-script/delete-block-script`, { data: data })
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

//Expiry symbol

export const getSymbolExpiryList = createAsyncThunk(
  "/setting/getSymbolExpiryList",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { searchText, pageNo, pageSize, appendData, segmentId } = data;
      axios
        .get(
          `settings/symbol-expiry/get-symbol-expires-by-segment/${segmentId}/${pageNo}/${pageSize}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status === 200) {
            resolve({ data: data.data, appendData, searchText });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const addSymbolExpiryList = createAsyncThunk(
  "/setting/addSymbolExpiryList",
  (paramData, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.createdBy = userId;
      newFinalObject.expiryType = paramData.expiryType === "true" ? 1 : 2;
      newFinalObject.segmentId = paramData.segmentId.value;
      newFinalObject.symbol = paramData.symbol.value;
      if (paramData.expiryType === "true") {
        newFinalObject.newExpiryDays = paramData.newExpiryDays;
        newFinalObject.beforeExpiryDays = paramData.beforeExpiryDays;
      } else {
        newFinalObject.startDate = moment(paramData.startDate)
          .tz("Asia/Kolkata")
          .format();
        newFinalObject.endDate = moment(paramData.endDate)
          .tz("Asia/Kolkata")
          .format();
        newFinalObject.expiry =
          paramData.expiry && moment(paramData.expiry.value).format();
      }
      axios
        .post(`settings/symbol-expiry/save-symbol-expiry`, newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const deleteExpirySetting = createAsyncThunk(
  "/setting/expiry-delete-setting",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id } = data;
      axios
        .delete(`settings/symbol-expiry/delete-symbol-expiry/${id}`)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getSymbolsExpirySymbolList = createAsyncThunk(
  "/symbollist/expirysetting/get",
  ({ id }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `settings/symbol-expiry/get-symbol-expiry-symbols-by-segment/${id}`
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setIsQuantitySettingUpdated: (state, action) => {
      state.isQuantitySettingUpdated = action.payload;
    },
    setSymbolOffQuoteList: (state, action) => {
      state.symbolOffQuoteList = action.payload;
    },
    setIsSymbolOffQuoteUpdated: (state, action) => {
      state.isSymbolOffQuoteUpdated = action.payload;
    },
    setSymbolOffQuoteLoading: (state, action) => {
      state.symbolOffQuoteLoading = action.payload;
    },
    setSearchTextSymbolOffQuote: (state, action) => {
      state.searchTextSymbolOffQuote = action.payload;
    },

    setSelectedAccountInQuantitySetting: (state, action) => {
      state.selectedAccountInQuantitySetting = action.payload;
    },
    setStartApiCallQuantitySettingList: (state, action) => {
      state.startApiCallQuantitySettingList = action.payload;
    },
    setAccountSettingType: (state, action) => {
      state.accountSettingType = action.payload;
    },
    setSearchTextQuantitySetting: (state, action) => {
      state.searchTextQuantitySettings = action.payload;
    },
    setStartPageQuantitySetting: (state, action) => {
      state.currentPageQuantitySettings = action.payload;
    },
    setStartPageCepeSetting: (state, action) => {
      state.currentPageCepeSettings = action.payload;
    },
    setCepeSettingsObj: (state, action) => {
      state.cepeSettingsObj = action.payload;
    },
    setEditSettingsObj: (state, action) => {
      state.editSettingsObj = action.payload;
    },
    setIsCepeSettingUpdated: (state, action) => {
      state.isCepeSettingUpdated = action.payload;
    },
    setTotalRecordQuantitySettingQuantity: (state, action) => {
      state.totalRecordQuantitySettings = action.payload;
    },
    setTotalRecordsCepeSettings: (state, action) => {
      state.totalRecordsCepeSettings = action.payload;
    },
    setSearchTextCepeSettings: (state, action) => {
      state.searchTextCepeSettings = action.payload;
    },
    setQuantitySettingList: (state, action) => {
      state.quantityList = action.payload;
    },
    //blockScript setting
    setIsBlockScriptSettingUpdated: (state, action) => {
      state.isBlockScriptSettingUpdated = action.payload;
    },
    setSelectedAccountInBlockScriptSetting: (state, action) => {
      state.selectedAccountInQuantitySetting = action.payload;
    },
    setStartApiCallBlockScriptSettingList: (state, action) => {
      state.startApiCallQuantitySettingList = action.payload;
    },
    setSearchTextBlockScriptSetting: (state, action) => {
      state.searchTextBlockScriptSettings = action.payload;
    },
    setStartPageBlockScriptSetting: (state, action) => {
      state.startQuantitySetting = action.payload;
    },
    setBlockScriptSettingList: (state, action) => {
      state.blockScriptList = action.payload;
    },
    //Tansfer reducer
    setTransferAdd: (state, action) => {
      state.transferAddSuccess = action.payload;
    },

    //Expiry
    setExpirySettingList: (state, action) => {
      state.settingSymbolExpirysList = action.payload;
    },
    setIsSymbolExpirySettingUpdated: (state, action) => {
      state.isSymbolExpirySettingUpdated = action.payload;
    },
    setSortSettingExpiry: (state, action) => {
      state.sortSettingExpiryArr = action.payload;
    },
    setStartPageExpirySetting: (state, action) => {
      state.currentPageSymbolExpirySettings = action.payload;
    },
    setSelectedSegmentInExpirySetting: (state, action) => {
      state.selectedSegmentInExpirySetting = action.payload;
    },
    setTotalRecordSymbolExpirySettings: (state, action) => {
      state.totalRecordSymbolExpirySettings = action.payload;
    },
    setExpirySettingDeleteId: (state, action) => {
      state.expirySettingDeleteId = action.payload;
    },
    setSearchTextSymbolExpirySettings: (state, action) => {
      state.searchTextSymbolExpirySettings = action.payload;
    },
    setSymbolsExpirySymbolList: (state, action) => {
      state.symbolsExpirySymbolList = action.payload;
    },
    setSymbolDetailForQtySetting: (state, action) => {
      state.symbolDetailForQtySetting = action.payload;
    },
    setIsQuantityGet: (state, action) => {
      state.isQuantityGet = action.payload;
    },
    setQuantitySettingFilterValue: (state, action) => {
      state.quantitySettingFilterValue = action.payload;
    },
    setQtySettingFromMasterAcc: (state, action) => {
      state.qtySettingFromMasterAcc = action.payload;
    },
  },
  extraReducers: {
    //Quantity setting
    [getQuantityList.pending]: (state, action) => {
      state.quantityLoading = true;
      state.startApiCallQuantitySettingList = false;
    },
    [getQuantityList.rejected]: (state, action) => {
      state.quantityList = [];
      state.quantityLoading = false;
      state.totalRecordQuantitySettings = 0;
      state.startApiCallQuantitySettingList = false;
    },
    [getQuantityList.fulfilled]: (state, action) => {
      let quantityListData = [];
      if (action.payload.appendData) {
        quantityListData = [
          ...state.quantityList,
          ...action.payload.data.records,
        ];
      } else {
        quantityListData = action.payload.data.records;
      }
      state.quantityList = quantityListData.map((item) => {
        return { ...item, isDelete: false };
      });

      state.quantityLoading = false;
      if (action.payload.searchText) {
        state.totalRecordQuantitySettings = action.payload.data.records.length;
      } else {
        state.totalRecordQuantitySettings = action.payload.data.totalRecords;
      }
      state.startApiCallQuantitySettingList = false;
    },
    [getSymbolOffQuoteList.fulfilled]: (state, action) => {
      let symbolQuoteData = []
      if (action.payload?.appendData) {
        symbolQuoteData = [...state.symbolOffQuoteList, ...action.payload?.data?.records]
      } else {
        symbolQuoteData = [...action.payload?.data?.records]
      }
      state.symbolOffQuoteList = symbolQuoteData
      state.totalRecordsSymbolOffQuote = action.payload?.data?.totalRecords
      state.symbolOffQuoteLoading = false;
    },
    [getSymbolOffQuoteList.pending]: (state, action) => {
      state.symbolOffQuoteList = [];
      state.symbolOffQuoteLoading = true;
    },
    [getSymbolOffQuoteList.rejected]: (state, action) => {
      state.symbolOffQuoteList = [];
      state.symbolOffQuoteLoading = false;
    },
    [createSymbolOffQuote.pending]: (state, action) => {
      ;
      state.symbolOffQuoteLoading = true;
    },
    [createSymbolOffQuote.rejected]: (state, action) => {
      state.symbolOffQuoteLoading = false;
    },
    [createSymbolOffQuote.fulfilled]: (state, action) => {
      state.isSymbolOffQuoteUpdated = true;
      state.symbolOffQuoteLoading = false;
    },
    [deleteSymbolOffQuote.pending]: (state, action) => {
      state.symbolOffQuoteLoading = true;
    },
    [deleteSymbolOffQuote.rejected]: (state, action) => {
      state.symbolOffQuoteLoading = false;
    },
    [deleteSymbolOffQuote.fulfilled]: (state, action) => {
      state.isSymbolOffQuoteUpdated = true;
      state.symbolOffQuoteLoading = false;
    },
    [getQuantityDetail.pending]: (state, action) => {
      state.symbolDetailForQtySetting = {}
      state.cepeSettingLoading = true;
    },
    [getQuantityDetail.fulfilled]: (state, action) => {
      // if (action.payload.data?.records?.length > 0) {
      //   let obj = action.payload.data.records[0];
      //   if (obj.segmentId === 1 || obj.segmentId === 5) {
      //     if (action.payload.userRole === 'Super Admin') {
      //       if (obj.settingType === 1 || obj.settingType === 2) {
      //         let minQtyValue = obj.qty * 0.01
      //         minQtyValue = minQtyValue <= 1 ? 1 : Math.trunc(obj.qty * 0.01)
      //         let minLotValue = (minQtyValue / obj.qty).toFixed(2)
      //         let maxQtyPositionLimit = obj.positionLimit * obj.qty
      //         maxQtyPositionLimit = Math.trunc(maxQtyPositionLimit)
      //         let maxLotPositionLimit = (maxQtyPositionLimit / obj.qty).toFixed(2)
      //         let maxQtyMaxOrder = obj.maxOrder * obj.qty
      //         maxQtyMaxOrder = Math.trunc(maxQtyMaxOrder)
      //         let maxLotMaxOrder = (maxQtyMaxOrder / obj.qty).toFixed(2)
      //         let maxQtyMinOrder = obj.maxOrder * obj.qty
      //         maxQtyMinOrder = Math.trunc(maxQtyMinOrder)
      //         let maxLotMinOrder = (maxQtyMinOrder / obj.qty).toFixed(2)
      //         obj = { ...obj, minQtyValue, minLotValue, maxLotPositionLimit, maxLotMaxOrder, maxLotMinOrder }
      //         /*    let qtyPositionLimit = obj.positionLimit
      //            let qtyMaxOrder = obj.maxOrder
      //            let qtyMinOrder = obj.minOrder
      //            let lotPositionLimit = Number((qtyPositionLimit / obj.qty).toFixed(2))
      //            let lotMaxOrder = Number((qtyMaxOrder / obj.qty).toFixed(2))
      //            let lotMinOrder = Number((qtyMinOrder / obj.qty).toFixed(2)) */
      //         // obj = { ...obj, qtyPositionLimit, qtyMaxOrder, qtyMinOrder, lotPositionLimit, lotMaxOrder, lotMinOrder }
      //       } else if (obj.settingType === 2) {
      //         let lotPositionLimit = obj.positionLimit
      //         let lotMaxOrder = obj.maxOrder
      //         let lotMinOrder = obj.minOrder
      //         let qtyPositionLimit = Math.round(lotPositionLimit * obj.qty)
      //         let qtyMaxOrder = Math.round(lotMaxOrder * obj.qty)
      //         let qtyMinOrder = Math.round(lotMinOrder * obj.qty)
      //         obj = { ...obj, qtyPositionLimit, qtyMaxOrder, qtyMinOrder, lotPositionLimit, lotMaxOrder, lotMinOrder }
      //       }
      //       state.symbolDetailForQtySetting = obj
      //     } else {
      //       if (obj.settingType === 1) {
      //         let qtyPositionLimit = obj.positionLimit
      //         let qtyMaxOrder = obj.maxOrder
      //         let qtyMinOrder = obj.minOrder
      //         let lotPositionLimit = Number((qtyPositionLimit / obj.qty).toFixed(2))
      //         let lotMaxOrder = Number((qtyMaxOrder / obj.qty).toFixed(2))
      //         let lotMinOrder = Number((qtyMinOrder / obj.qty).toFixed(2))
      //         let minQtyValue = obj.qty * 0.01
      //         minQtyValue = minQtyValue <= 1 ? 1 : Math.trunc(obj.qty * 0.01)
      //         const minLotValue = (minQtyValue / obj.qty).toFixed(2)

      //         /*  let minQty = qty * 0.01
      //          minQty = minQty <= 1 ? 1 : Math.trunc(qty * 0.01)
      //          const minLot = (minQty / qty).toFixed(2)
      //          let maxQty = lot * qty
      //          maxQty = Math.trunc(maxQty)
      //          const maxLot = (maxQty / qty).toFixed(2) */
      //         obj = { ...obj, qtyPositionLimit, qtyMaxOrder, qtyMinOrder, lotPositionLimit, lotMaxOrder, lotMinOrder }
      //       } else if (obj.settingType === 2) {
      //         let lotPositionLimit = obj.positionLimit
      //         let lotMaxOrder = obj.maxOrder
      //         let lotMinOrder = obj.minOrder
      //         let qtyPositionLimit = Math.round(lotPositionLimit * obj.qty)
      //         let qtyMaxOrder = Math.round(lotMaxOrder * obj.qty)
      //         let qtyMinOrder = Math.round(lotMinOrder * obj.qty)
      //         obj = { ...obj, qtyPositionLimit, qtyMaxOrder, qtyMinOrder, lotPositionLimit, lotMaxOrder, lotMinOrder }
      //       }
      //       state.symbolDetailForQtySetting = obj
      //     }
      //   } else {
      //     state.symbolDetailForQtySetting = action.payload.data.records[0]
      //   }
      // } else {
      //   state.symbolDetailForQtySetting = {}
      // }
      if (action.payload.data?.records?.length > 0) {
        let obj = action.payload.data.records[0];
        if (obj.symbol !== "SEGMENT" && (obj.segmentId === 1 || obj.segmentId === 5)) {
          if (obj.settingType === 1) {
            let maxQtyPositionLimit = obj.positionLimit
            let maxQtyMaxOrder = obj.maxOrder
            let maxQtyMinOrder = obj.qty
            let minQtyValue = obj.qty * 0.01
            minQtyValue =  0.10//minQtyValue <= 1 ? 1 : Math.ceil(obj.qty * 0.01)
            let minLotValue = (minQtyValue / obj.qty).toFixed(2)
            let maxLotPositionLimit = (maxQtyPositionLimit / obj.qty).toFixed(2)
            let maxLotMaxOrder = (maxQtyMaxOrder / obj.qty).toFixed(2)
            let maxLotMinOrder = (maxQtyMinOrder / obj.qty).toFixed(2)
            obj = { ...obj, minQtyValue, minLotValue, maxLotPositionLimit, maxQtyPositionLimit, maxLotMaxOrder, maxQtyMaxOrder, maxLotMinOrder, maxQtyMinOrder }
          } else if (obj.settingType === 2) {
            let maxLotPositionLimit = obj.positionLimit
            let maxLotMaxOrder = obj.maxOrder
            let maxLotMinOrder = obj.minOrder
            let maxQtyPositionLimit = Math.trunc(obj.positionLimit * obj.qty)
            let maxQtyMaxOrder = Math.trunc(obj.maxOrder * obj.qty)
            let maxQtyMinOrder = Math.trunc(obj.minOrder * obj.qty)
            let minQtyValue = obj.qty * 0.01
            minQtyValue = 0.10//minQtyValue <= 1 ? 1 : Math.ceil(obj.qty * 0.01)
            let minLotValue = (minQtyValue / obj.qty).toFixed(2)
            obj = { ...obj, minQtyValue, minLotValue, maxLotPositionLimit, maxQtyPositionLimit, maxLotMaxOrder, maxQtyMaxOrder, maxLotMinOrder, maxQtyMinOrder }
          } else {
            obj = { ...obj, minQtyValue: 1, maxQtyPositionLimit: obj.positionLimit, maxQtyMaxOrder: obj.maxOrder, maxQtyMinOrder: obj.minOrder }
          }
          state.symbolDetailForQtySetting = obj
          state.cepeSettingLoading = false;
        } else {
          state.symbolDetailForQtySetting = obj
          state.cepeSettingLoading = false;
        }
      } else {
        state.symbolDetailForQtySetting = {}
        state.cepeSettingLoading = false;
      }
    },
    [getQuantityDetail.rejected]: (state, action) => {
      state.symbolDetailForQtySetting = {}
      state.cepeSettingLoading = false;
    },
    [getCepeSettings.pending]: (state, action) => {
      state.cepeSettingLoading = true;
    },
    [getCepeSettings.rejected]: (state, action) => {
      state.cepeSettingList = [];
      state.cepeSettingLoading = false;
      state.totalRecordsCepeSettings = 0;
    },
    [getCepeSettings.fulfilled]: (state, action) => {
      let cepeSettingList = [];
      if (action.payload.appendData) {
        cepeSettingList = [
          ...state.cepeSettingList,
          ...action.payload.data.records,
        ];
      } else {
        cepeSettingList = action.payload.data.records;
      }
      if (action.payload.searchText) {
        state.totalRecordsCepeSettings = action.payload.data.records.length;
      } else {
        state.totalRecordsCepeSettings = action.payload.data.totalRecords;
      }
      state.cepeSettingList = cepeSettingList
      state.cepeSettingLoading = false;
      state.isCepeSettingUpdated = false
    },
    [createCepeSettings.pending]: (state, action) => {
      state.isCepeSettingUpdated = false;
      state.cepeSettingLoading = true;
    },
    [createCepeSettings.rejected]: (state, action) => {
      state.isCepeSettingUpdated = false;
      state.cepeSettingLoading = false;
    },
    [createCepeSettings.fulfilled]: (state, action) => {
      state.isCepeSettingUpdated = true;
      state.cepeSettingLoading = false;
    },
    [deleteCepeSettings.pending]: (state, action) => {
      state.isCepeSettingUpdated = false;
      state.cepeSettingLoading = true;
    },
    [deleteCepeSettings.rejected]: (state, action) => {
      state.isCepeSettingUpdated = false;
      state.cepeSettingLoading = false;
    },
    [deleteCepeSettings.fulfilled]: (state, action) => {
      state.isCepeSettingUpdated = true;
      state.cepeSettingLoading = false;
    },
    [addQuentitySetting.pending]: (state, action) => {
      state.isQuantitySettingUpdated = false;
      state.quantityLoading = true;
    },
    [addQuentitySetting.rejected]: (state, action) => {
      state.isQuantitySettingUpdated = false;
      state.quantityLoading = false;
    },
    [addQuentitySetting.fulfilled]: (state, action) => {
      state.isQuantitySettingUpdated = true;
      state.quantityLoading = false;
    },
    [deleteQuantitySetting.pending]: (state, action) => {
      state.isQuantitySettingUpdated = false;
      state.quantityLoading = true;
    },
    [deleteQuantitySetting.rejected]: (state, action) => {
      state.isQuantitySettingUpdated = false;
      state.quantityLoading = false;
    },
    [deleteQuantitySetting.fulfilled]: (state, action) => {
      state.isQuantitySettingUpdated = true;
      state.quantityLoading = false;
    },

    //Transfer setting
    [addTrasferSetting.pending]: (state, action) => {
      state.transferAddSuccess = false;
      state.transferAddLoading = true;
    },
    [addTrasferSetting.rejected]: (state, action) => {
      state.transferAddSuccess = false;
      state.transferAddLoading = false;
    },
    [addTrasferSetting.fulfilled]: (state, action) => {
      state.transferAddSuccess = true;
      state.transferAddLoading = false;
    },

    //Expiry symbol/script
    [addSymbolExpiryList.pending]: (state, action) => {
      state.isSymbolExpirySettingUpdated = false;
    },
    [addSymbolExpiryList.rejected]: (state, action) => {
      state.isSymbolExpirySettingUpdated = false;
    },
    [addSymbolExpiryList.fulfilled]: (state, action) => {
      state.isSymbolExpirySettingUpdated = true;
    },
    [getSymbolExpiryList.pending]: (state, action) => {
      state.symbolExpiryLoading = true;
      state.startApiCallBlockScriptSettingList = false;
    },
    [getSymbolExpiryList.rejected]: (state, action) => {
      state.settingSymbolExpirysList = [];
      state.symbolExpiryLoading = false;
      state.totalRecordSymbolExpirySettings = 0;
      state.startApiCallBlockScriptSettingList = false;
    },
    [getSymbolExpiryList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.settingSymbolExpirysList = [
          ...state.settingSymbolExpirysList,
          ...action.payload.data.records,
        ];
      } else {
        state.settingSymbolExpirysList = action.payload.data.records;
      }
      state.symbolExpiryLoading = false;
      if (action.payload.searchText) {
        state.totalRecordSymbolExpirySettings =
          action.payload.data.records.length;
      } else {
        state.totalRecordSymbolExpirySettings =
          action.payload.data.totalRecords;
      }
      state.startApiCallBlockScriptSettingList = false;
    },
    [deleteExpirySetting.pending]: (state, action) => {
      state.isSymbolExpirySettingUpdated = false;
    },
    [deleteExpirySetting.rejected]: (state, action) => {
      state.isSymbolExpirySettingUpdated = false;
    },
    [deleteExpirySetting.fulfilled]: (state, action) => {
      state.isSymbolExpirySettingUpdated = true;
    },
    [getSymbolsExpirySymbolList.pending]: (state, action) => {
      state.symbolsExpirySymbolList = [];
    },
    [getSymbolsExpirySymbolList.rejected]: (state, action) => {
      state.symbolsExpirySymbolList = [];
    },
    [getSymbolsExpirySymbolList.fulfilled]: (state, action) => {
      state.symbolsExpirySymbolList = action.payload;
    },

    //Block Symbol/script
    [addBlockSymbolSetting.pending]: (state, action) => {
      state.isBlockScriptSettingUpdated = false;
    },
    [addBlockSymbolSetting.rejected]: (state, action) => {
      state.isBlockScriptSettingUpdated = false;
    },
    [addBlockSymbolSetting.fulfilled]: (state, action) => {
      state.isBlockScriptSettingUpdated = true;
    },

    [getBlockSymbolList.pending]: (state, action) => {
      state.blockScriptLoading = true;
      state.startApiCallSymbolExpirySettingList = false;
    },
    [getBlockSymbolList.rejected]: (state, action) => {
      state.blockScriptList = [];
      state.blockScriptLoading = false;
      state.totalRecordBlockScriptSettings = 0;
      state.startApiCallSymbolExpirySettingList = false;
    },
    [getBlockSymbolList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.blockScriptList = [
          ...state.blockScriptList,
          ...action.payload.data.records,
        ];
      } else {
        state.blockScriptList = action.payload.data.records;
      }
      state.blockScriptLoading = false;
      if (action.payload.searchText) {
        state.totalRecordBlockScriptSettings =
          action.payload.data.records.length;
      } else {
        state.totalRecordBlockScriptSettings = action.payload.data.totalRecords;
      }
      state.startApiCallSymbolExpirySettingList = false;
    },
    [deleteBlockScriptSetting.pending]: (state, action) => {
      state.isBlockScriptSettingUpdated = false;
    },
    [deleteBlockScriptSetting.rejected]: (state, action) => {
      state.isBlockScriptSettingUpdated = false;
    },
    [deleteBlockScriptSetting.fulfilled]: (state, action) => {
      state.isBlockScriptSettingUpdated = true;
    },
  },
});

export const {
  setTransferAdd,
  setIsQuantitySettingUpdated,
  setIsQuantityGet,
  setSelectedAccountInQuantitySetting,
  setAccountSettingType,
  setStartApiCallQuantitySettingList,
  setSearchTextQuantitySetting,
  setIsCepeSettingUpdated,
  setStartPageCepeSetting,
  setCepeSettingsObj,
  setEditSettingsObj,
  setSearchTextCepeSettings,
  setStartPageQuantitySetting,
  setTotalRecordQuantitySettingQuantity,
  setTotalRecordsCepeSettings,
  setQuantitySettingList,
  setQuantitySettingFilterValue,
  setSymbolOffQuoteList,
  setIsSymbolOffQuoteUpdated,
  setSymbolOffQuoteLoading,
  setSearchTextSymbolOffQuote,
  setIsBlockScriptSettingUpdated,
  setStartPageBlockScriptSetting,
  setBlockScriptSettingList,

  setSearchTextBlockScriptSetting,
  setSymbolDetailForQtySetting,
  setIsSymbolExpirySettingUpdated,
  setSortSettingExpiry,
  setStartPageExpirySetting,
  setSelectedSegmentInExpirySetting,
  setTotalRecordSymbolExpirySettings,
  setExpirySettingList,
  setExpirySettingDeleteId,
  setSearchTextSymbolExpirySettings,
  setSymbolsExpirySymbolList,
  setQtySettingFromMasterAcc
} = settingSlice.actions;

export default settingSlice.reducer;