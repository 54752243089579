import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import axios from "axios";
import { showMessage } from "store/reducers/common.slice";
import { getSessionData } from "utils/common";
const initialState = {
  sortFinanceCashArr: [],
  financeCashFilterDateObject: {
    startDate: new Date(),
    endDate: new Date(),
  },
  sortFinanceBankEntryArr: [],

  sortFinanceJVEntryArr: [],
  financeBankEntryList: [],
  financeBankEntryLoading: false,
  financeTrialBalance: [],
  financeTrialBalanceForBook: [],
  trialBalanceCreditAccount: [],
  accountSettingType: 1,
  isFinanceJvEntryUpdated: false,
  financeJvEntrySearchText: "",
  financeJvEntryList: [],
  financeJvEntryLoading: false,
  financeJvEntryTotal: 0,
  currentPageCashEntry: 0,
  financeJvEntryLimit: 10,
  financeJvEntryCurrentPage: 1,
  startApiFinanceJvEntryList: true,
  financeJvEntryFilterDateObject: {
    startDate: new Date(),
    endDate: new Date(),
  },
  totalCahEntryListRecords: 0,
  isFinanceCahEntryUpdated: false,
  financeCahEntrySearchText: "",
  financeCahEntryList: [],
  financeCahEntryLoading: false,
  financeCahEntryTotal: 0,
  financeCahEntryLimit: 10,
  financeCahEntryCurrentPage: 1,
  startApiFinanceCahEntryList: true,
  filterFinanceCahEntryData: {
    tradeType: "1",
    segmentId: "",
    userId: "",
    startDate: new Date(),
    endDate: new Date(),
  },
};

const bankEntryDummy = [];

export const getFinanceCashEntryList = createAsyncThunk(
  "/finance/cashEntry/get",
  ({ searchText, pageNo, pageSize, appendData, startDate, endDate }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `finance/cash-entry/get-cash-entry/${userId}/${pageNo}/${pageSize}/${moment(
            startDate
          ).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const addFinanceCashEntry = createAsyncThunk(
  "finance/cashentry/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let finalObject = {};
      finalObject.id = data.id ? data.id : 0;
      if (data.txId) {
        finalObject.txId = data.txId ? data.txId : 0;
      }
      finalObject.userId = data.customeraccountId
        ? Number(data.customeraccountId.value)
        : 0;
      finalObject.createdBy = userId;
      finalObject.cashbookId =
        data && data.book?.value ? parseInt(data.book.value) : 0;
      finalObject.cashType = data.cashType ? data.cashType : "";
      finalObject.entryDate = data.entryDate
        ? data.entryDate
        : moment().format();
      finalObject.amount = data.amount ? data.amount : 1;
      finalObject.tradingLedgerBalance = data.tradingLedgerBalance
        ? data.tradingLedgerBalance
        : 1;
      finalObject.remark = data.remark ? data.remark : "self";
      finalObject.cashBookTxId = data.cashBookTxId ? data.cashBookTxId : 0;
      axios
        .post("finance/cash-entry/save-cash-entry", finalObject)
        .then(({ data: dataRep }) => {
          if (dataRep.status == 200) {
            dispatch(
              showMessage({ message: dataRep.message, varient: "success" })
            );
            resolve(data.data);
          } else {
            reject(dataRep);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const deleteFinanceCashEntry = createAsyncThunk(
  "finance/cashentry/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, txId, cashBookTxId } = data;
      axios
        .delete(
          `finance/cash-entry/delete-cash-entry/${id}/${txId}/${cashBookTxId}`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const deleteFinanceJvEntry = createAsyncThunk(
  "finance/jventry/delete",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id, creditTxId, debitTxId } = data;
      axios
        .delete(
          `finance/jv-entry/delete-jv-entry/${id}/${creditTxId}/${debitTxId}`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const getFinanceBankEntryList = createAsyncThunk(
  "/finance/BankEntry",
  () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(bankEntryDummy);
      }, 1000);
    });
  }
);

export const getFinanceJvEntryList = createAsyncThunk(
  "/finance/JVEntry",
  ({ searchText, pageNo, pageSize, appendData, startDate, endDate }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `finance/jv-entry/get-jv-entry/${userId}/${pageNo}/${pageSize}/${moment(
            startDate
          ).format("YYYY-MM-DD")}/${moment(endDate).format("YYYY-MM-DD")}${searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          resolve({ data: data.data, appendData });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getFinanceTrialBalance = createAsyncThunk(
  "report/balance/get",
  (dataParam) => {
    return new Promise((resolve, reject) => {
      let finalObject = {};
      finalObject.userId = dataParam.userId;
      finalObject.startDate = moment().format();
      finalObject.endDate = moment().format();
      finalObject.dateFilter =
        dataParam && dataParam.dateFilter ? dataParam.dateFilter : 1;
      finalObject.orderBy =
        dataParam && dataParam.orderBy ? dataParam.orderBy.value : 0;
      finalObject.filterBy =
        dataParam && dataParam.filterBy ? dataParam.filterBy : 0;
      finalObject.withZero = true

      axios
        .post("reports/trial-balance-report/get-trial-balance", finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({
              data: data.data,
              isCreditAccount: dataParam.isCreditAccount,
            });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getFinanceTrialBalanceForBook = createAsyncThunk(
  "report/balance/getBalanceForBook",
  (dataParam) => {
    return new Promise((resolve, reject) => {
      let finalObject = {};
      finalObject.userId = dataParam.userId;
      finalObject.startDate = moment().format();
      finalObject.endDate = moment().format();
      finalObject.dateFilter =
        dataParam && dataParam.dateFilter ? dataParam.dateFilter : 1;
      finalObject.orderBy =
        dataParam && dataParam.orderBy ? dataParam.orderBy.value : 0;
      finalObject.filterBy =
        dataParam && dataParam.filterBy ? dataParam.filterBy : 0;
      finalObject.withZero = true

      axios
        .post("reports/trial-balance-report/get-trial-balance", finalObject)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({
              data: data.data,
              isCreditAccount: dataParam.isCreditAccount,
            });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const addFinanceJvEntry = createAsyncThunk(
  "finance/jv-entry/add",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId, roles } = getSessionData();
      let finalObject = {};
      finalObject.id = data.id ? data.id : 0;
      finalObject.creditTxId = data.creditTxId ? data.creditTxId : 0;
      finalObject.debitTxId = data.debitTxId ? data.debitTxId : 0;
      finalObject.creditAccountId = data.creditAccountId
        ? Number(data.creditAccountId.value)
        : 0;
      finalObject.debitAccountId = data.debitAccountId
        ? Number(data.debitAccountId.value)
        : 0;
      finalObject.createdBy = userId;
      finalObject.entryDate = data.entryDate
        ? data.entryDate
        : moment().format();
      finalObject.amount = data.amount ? data.amount : 1;
      finalObject.remark = data.remark ? data.remark : "self";

      axios
        .post("finance/jv-entry/save-jv-entry", finalObject)
        .then(({ data: dataRep }) => {
          if (dataRep.status == 200) {
            dispatch(
              showMessage({ message: dataRep.message, varient: "success" })
            );
            resolve(data.data);
          } else {
            reject(dataRep);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const financeSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    setSortFinanceCash: (state, action) => {
      state.sortFinanceCashArr = action.payload;
    },
    setFinanceCashFilterDateObject: (state, action) => {
      state.financeCashFilterDateObject = action.payload;
    },
    setSortFinanceBankEntry: (state, action) => {
      state.sortFinanceBankEntryArr = action.payload;
    },
    setFinanceBankEntryFilterDateObject: (state, action) => {
      state.financeBankEntryFilterDateObject = action.payload;
    },
    setSortFinanceJVEntry: (state, action) => {
      state.sortFinanceJVEntryArr = action.payload;
    },
    setFinanceCashEntrySearchText: (state, action) => {
      state.financeCahEntrySearchText = action.payload;
    },
    setFinanceCahEntryCurrentPage: (state, action) => {
      state.financeCahEntryCurrentPage = action.payload;
    },
    setTotalCahEntryListRecords: (state, action) => {
      state.totalCahEntryListRecords = action.payload;
    },
    setCurrentPageCashEntry: (state, action) => {
      state.currentPageCashEntry = action.payload;
    },
    setFinanceJvFilterDateObject: (state, action) => {
      state.financeJvEntryFilterDateObject = action.payload;
    },
    setFinanceJvEntrySearchText: (state, action) => {
      state.financeJvEntrySearchText = action.payload;
    },
    setFinanceJvEntryCurrentPage: (state, action) => {
      state.financeJvEntryCurrentPage = action.payload;
    },
    setFinanceJvEntryList: (state, action) => {
      state.financeJvEntryList = action.payload;
    },
    setFinanceTrialBalance: (state, action) => {
      state.financeTrialBalance = action.payload;
    },
    setTrialBalanceCreditAccount: (state, action) => {
      state.trialBalanceCreditAccount = action.payload;
    },
    setAccountSettingType: (state, action) => {
      state.accountSettingType = action.payload;
    },
    setFinanceTrialBalanceForBook: (state, action) => {
      state.financeTrialBalanceForBook = action.payload;
    },
  },
  extraReducers: {
    //CashEntry
    [addFinanceCashEntry.pending]: (state, action) => {
      state.isFinanceCahEntryUpdated = false;
    },
    [addFinanceCashEntry.rejected]: (state, action) => {
      state.isFinanceCahEntryUpdated = false;
    },
    [addFinanceCashEntry.fulfilled]: (state, action) => {
      state.isFinanceCahEntryUpdated = true;
    },
    [getFinanceCashEntryList.pending]: (state, action) => {
      state.financeCahEntryLoading = true;
      state.totalCahEntryListRecords = 0
    },
    [getFinanceCashEntryList.rejected]: (state, action) => {
      state.financeCahEntryList = [];
      state.totalCahEntryListRecords = 0
      state.financeCahEntryLoading = false;
    },
    [getFinanceCashEntryList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.financeCahEntryList = [
          ...state.financeCahEntryList,
          ...action.payload.data.records,
        ];
      } else {
        state.totalCahEntryListRecords = action.payload.data?.totalRecords ? action.payload.data?.totalRecords : 0
        state.financeCahEntryList = action.payload.data?.records;
      }
      state.financeCahEntryLoading = false;
    },

    //bankEntry
    [getFinanceBankEntryList.pending]: (state, action) => {
      state.financeBankEntryList = [];
      state.financeBankEntryLoading = true;
    },
    [getFinanceBankEntryList.rejected]: (state, action) => {
      state.financeBankEntryList = [];
      state.financeBankEntryLoading = false;
    },
    [getFinanceBankEntryList.fulfilled]: (state, action) => {
      state.financeBankEntryList = action.payload;
      state.financeBankEntryLoading = false;
    },

    //JVEntry
    [addFinanceJvEntry.pending]: (state, action) => {
      state.isFinanceJvEntryUpdated = false;
    },
    [addFinanceJvEntry.rejected]: (state, action) => {
      state.isFinanceJvEntryUpdated = false;
    },
    [addFinanceJvEntry.fulfilled]: (state, action) => {
      state.isFinanceJvEntryUpdated = true;
    },
    [getFinanceJvEntryList.pending]: (state, action) => {
      state.financeJvEntryLoading = true;
    },
    [getFinanceJvEntryList.rejected]: (state, action) => {
      state.financeJvEntryList = [];
      state.financeJvEntryLoading = false;
    },
    [getFinanceJvEntryList.fulfilled]: (state, action) => {
      if (action.payload.appendData) {
        state.financeJvEntryList = [
          ...state.financeJvEntryList,
          ...action.payload.data.records,
        ];
      } else {
        state.financeJvEntryList = action.payload.data.records;
      }
      state.financeJvEntryLoading = false;
    },
    [getFinanceTrialBalance.rejected]: (state, action) => {
      state.financeTrialBalance = [];
    },
    [getFinanceTrialBalance.fulfilled]: (state, action) => {
      if (action.payload.isCreditAccount) {
        state.trialBalanceCreditAccount = action.payload.data[0]?.data || [];
      } else {
        state.financeTrialBalance = action.payload.data[0]?.data || []
      }
    },
    [getFinanceTrialBalanceForBook.pending]: (state, action) => {
      state.financeTrialBalanceForBook = [];
    },
    [getFinanceTrialBalanceForBook.rejected]: (state, action) => {
      state.financeTrialBalanceForBook = [];
    },
    [getFinanceTrialBalanceForBook.fulfilled]: (state, action) => {
      state.financeTrialBalanceForBook = action.payload?.data[0]?.data || [];
    },
    [deleteFinanceCashEntry.pending]: (state, action) => {
      state.isFinanceCahEntryUpdated = false;
    },
    [deleteFinanceCashEntry.rejected]: (state, action) => {
      state.isFinanceCahEntryUpdated = false;
    },
    [deleteFinanceCashEntry.fulfilled]: (state, action) => {
      state.isFinanceCahEntryUpdated = true;
    },
    [deleteFinanceJvEntry.pending]: (state, action) => {
      state.isFinanceJvEntryUpdated = false;
    },
    [deleteFinanceJvEntry.rejected]: (state, action) => {
      state.isFinanceJvEntryUpdated = false;
    },
    [deleteFinanceJvEntry.fulfilled]: (state, action) => {
      state.isFinanceJvEntryUpdated = true;
    },
  },
});

export const {
  setSortFinanceCash,
  setFinanceCashFilterDateObject,
  setSortFinanceBankEntry,
  setFinanceBankEntryFilterDateObject,
  setSortFinanceJVEntry,
  setFinanceCashEntrySearchText,
  setFinanceCahEntryCurrentPage,
  setFinanceJvFilterDateObject,
  setFinanceJvEntrySearchText,
  setFinanceJvEntryCurrentPage,
  setFinanceJvEntryList,
  setFinanceTrialBalance,
  setTrialBalanceCreditAccount,
  setAccountSettingType,
  setFinanceTrialBalanceForBook,
  setTotalCahEntryListRecords,
  setCurrentPageCashEntry
} = financeSlice.actions;

export default financeSlice.reducer;
