import React, { useCallback, useEffect, useRef, useState } from 'react'
import "../../assets/scss/mobile/newStyleMobile.scss";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlParam } from 'utils/common';
import { getSegmentOptionList } from 'store/reducers/common.slice';
import { setOrderBookList, getOrderBookList } from 'store/reducers/orderbook.slice';
import { Button, Input, Modal, Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { deleteOrder } from 'store/reducers/orderbook.slice';
import Swal from 'sweetalert2';

export default function MobileNewOrderBook() {
  let timerRef = useRef()
  const history = useHistory();
  const dispatch = useDispatch();
  const { segmentsList } = useSelector(({ common }) => common);
  const {
    orderBookList,
    orderBookPendingLoading,
    totalRecordOrderBook,
  } = useSelector(({ orderbook }) => orderbook);

  const [rowData, setRowData] = useState([]);
  const [infineHeight, setInfineHeight] = useState("300px");
  const [openModal, setOpenModal] = useState(false)
  const [selectedScript, setSelectedScript] = useState({})
  const [orderBookFilter, setOrderBookFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    searchText: "",
    appendData: false,
    segment: undefined,
    status: "pending",
  });

  const onGetOrderBookList = useCallback(async (filterData = {}) => {
    let orderBookOptions = { ...orderBookFilter, ...filterData }
    orderBookOptions.isCompleted = orderBookOptions.status
    await dispatch(getOrderBookList(orderBookOptions));
    setOrderBookFilter({ ...orderBookOptions, appendData: false })
  }, [dispatch, setOrderBookFilter, orderBookFilter])

  const loadData = useCallback(async () => {
    const { payload: segments = [] } = await dispatch(getSegmentOptionList());
    if (segments?.length > 0) {
      let segment = { label: null, value: null }
      let segmentId = getUrlParam(window.location.search, 'tab_id')
      let status = getUrlParam(window.location.search, 'status') || 'pending'
      if (segmentId) segment = segments?.find((x) => x?.value === Number(segmentId)) || { label: null, value: null }
      history.push(`${window.location.pathname}?tab_id=${segment?.value}&status=${status}`);
      await onGetOrderBookList({ segment, status })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadData()
    return () => {
      dispatch(setOrderBookList([]));
    };
  }, [dispatch, loadData]);

  useEffect(() => {
    if (orderBookList?.length > 0) {
      let mainHeight = orderBookList.length * 25 + 250;
      if (mainHeight <= 500) setInfineHeight(mainHeight + "px");
      else setInfineHeight("600px");
      setRowData(orderBookList);
    } else {
      setInfineHeight("200px");
      setRowData([]);
    }
  }, [dispatch, orderBookList]);

  const handleSearchChange = useCallback((e) => {
    onGetOrderBookList({
      pageNo: 1,
      searchText: e.target.value,
    })
  }, [onGetOrderBookList])

  const debounceHandleSearchChange = useCallback((e) => {
    if (timerRef?.current) clearTimeout(timerRef?.current);
    timerRef.current = setTimeout(() => {
      handleSearchChange(e)
    }, 300)
  }, [handleSearchChange])

  const fetchMoreDataInTable = useCallback(() => {
    if (rowData.length < totalRecordOrderBook) {
      onGetOrderBookList({
        pageNo: orderBookFilter?.pageNo + 1,
        appendData: true
      })
    }
  }, [onGetOrderBookList, rowData, totalRecordOrderBook, orderBookFilter])

  const onChangeStatusTab = useCallback((val) => {
    history.push(`${window.location.pathname}?tab_id=${orderBookFilter?.segment?.value}&status=${val}`);
    onGetOrderBookList({
      status: val,
      pageNo: 1
    })
  }, [history, onGetOrderBookList, orderBookFilter?.segment?.value])

  const onChangeSegment = useCallback((segment) => {
    history.push(`${window.location.pathname}?tab_id=${segment?.value}&status=${orderBookFilter?.status}`);
    onGetOrderBookList({
      segment: segment,
      pageNo: 1
    })
  }, [history, onGetOrderBookList, orderBookFilter?.status])

  const onDeleteOrder = useCallback(async (e, id) => {
    e.preventDefault();

    Swal.fire({
      title: "Are you sure?",
      text: `Are you sure want to cancel order?`,
      icon: "question",
      showCancelButton: true,
      width: 400,
      height: 400,
      confirmButtonColor: "#885b24",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { payload = {} } = await dispatch(deleteOrder({ id: id }));
        if (payload?.status === 200) {
          onGetOrderBookList({
            pageNo: 1
          })
        }
      }
    });
  }, [dispatch, onGetOrderBookList])

  const handleClickOnScript = useCallback(
    (script) => {
      setOpenModal(true)
      setSelectedScript(script)
    },
    [],
  )

  return (
    <div>
      <div className='morderbook_main_wrap'>
        <header>
          <div className='orderbook_header_wrap'>
            <div className='order_top_header'>
              <div className='d-flex align-items-center justify-content-between'>
                <h2 className='text_blue fw_bold'>Order Book</h2>
                {orderBookList?.length > 0 && <span className="material-symbols-outlined text_red delete_icon" onClick={(e) => { onDeleteOrder(e, orderBookList) }}>delete</span>}
              </div>
              <div className='order_search'>
                <div className="searchBar"><span className="material-symbols-outlined text_blue">search</span>
                  <input type="text" className="form-control" placeholder="Search Script" onChange={(e) =>
                    debounceHandleSearchChange(e, orderBookFilter)
                  } /></div>
              </div>
            </div>
            <div className='morder_tab_wrap'>
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item w-50 text-center" onClick={() => { if (orderBookFilter?.status !== "pending") onChangeStatusTab("pending") }}>
                  <button className={orderBookFilter?.status === "pending" ? "nav-link active" : "nav-link"} id="orderPending-tab" data-toggle="tab" role="tab" aria-controls="orderPending" aria-selected="true">PENDING{orderBookFilter?.status === "pending" && (rowData?.length > 0 ? ` (${rowData.length})` : ` (0)`)}</button>
                </li>
                <li className="nav-item w-50 text-center" onClick={() => { if (orderBookFilter?.status !== "completed") onChangeStatusTab("completed") }} >
                  <button className={orderBookFilter?.status === "completed" ? "nav-link active" : "nav-link"} id="orderCompleted-tab" data-toggle="tab" role="tab" aria-controls="orderCompleted" aria-selected="false">COMPLETED{orderBookFilter?.status === "completed" && (rowData?.length ? ` (${rowData.length})` : ` (0)`)}</button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="orderPending" role="tabpanel" aria-labelledby="orderPending-tab">
                  <div className='morder_tab_top'>
                    <ul>
                      <li className={orderBookFilter?.segment?.label === null && 'active'} onClick={() => { if (orderBookFilter?.segment?.label !== null) onChangeSegment({ label: null, value: null }) }}>Default</li>
                      {segmentsList?.map((segment, index) => {
                        return (
                          <li
                            key={`segment_${index}`}
                            className={orderBookFilter?.segment?.label === segment.label && 'active'}
                            onClick={() => { if (orderBookFilter?.segment?.label !== segment.label) onChangeSegment(segment) }}
                          >
                            {segment.label}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className="tab-pane fade" id="orderCompleted" role="tabpanel" aria-labelledby="orderCompleted-tab">...</div>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className='morderbook_inner_wrap'>
            <div className='orderbook_row_wrap'>
              <div
                id="custom_table_observer"
                className="table-responsive table-responsive-y"
                style={{
                  maxHeight: infineHeight,
                }}
              >
                <InfiniteScroll
                  dataLength={rowData?.length}
                  next={fetchMoreDataInTable}
                  hasMore={rowData?.length < totalRecordOrderBook}
                  scrollableTarget="custom_table_observer"
                >
                  <div className='orderbook_row'>
                    <ul>
                      {rowData?.map((order, i) => {
                        const { tradeDateTime, addTime, status, executionTypeName, script, rate, qty, lot } = order || {}
                        return (
                          <li className='bg_blue_box position-relative'>
                            <div className='removeIcon'>{status === "Pending" && <span className="material-symbols-outlined text_red delete_icon" onClick={(e) => onDeleteOrder(e, order?.id)}>
                              delete
                            </span>}</div>
                            <div className='row g-2 align-items-center' onClick={() => status === "Pending" && handleClickOnScript(order)}>
                              <div className='col-6'>
                                <p className={
                                  executionTypeName === "Buy Limit" ||
                                    executionTypeName === "Buy Stop"
                                    ? "green_bedge bedge"
                                    : "red_bedge bedge"
                                }>{executionTypeName}</p>
                              </div>
                              <div className='col-6'>
                                <p className='text_small d-flex align-items-center justify-content-end pending_order_wrap'>
                                  <span className="material-symbols-outlined schedule_icon">
                                    schedule
                                  </span>{(tradeDateTime || addTime).split(" ")[1]}
                                  <span className={
                                    status === "Pending"
                                      ? "text_small text_red px-2 fw_bold mr-3"
                                      : "text_small text_green px-2 fw_bold"
                                  }>{status}</span>
                                </p>
                              </div>
                              <div className='col-6'>
                                <p className='text_mediam text_blue fw_bold'>{script}</p>
                              </div>
                              <div className='col-6'>
                                <p className='text_small text_grey text-right'>Order Price<span className='text_blue text_small pl-1 fw_bold'>{rate}
                                </span></p>
                              </div>
                              <div className='col-6'>
                                <p className='text_blue text_small'>Qty.<span className='fw_bold pl-1'>{qty.toFixed(2)}</span></p>
                              </div>
                              <div className='col-6'>
                                <p className='text_blue text-right text_small'>Lot.<span className='text_blue fw_bold pl-1'>{lot.toFixed(2)}</span></p>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </InfiniteScroll>

              </div>
            </div>
          </div>
        </main>
      </div >
      {orderBookPendingLoading && (
        <div className="spinner-box">
          <Spinner className="ml-2" color="primary" />
        </div>
      )}
      {!orderBookPendingLoading && orderBookList?.length <= 0 && (
        <h4 className="d-flex justify-content-center text-danger mt-2">
          Data Not Found
        </h4>
      )}
      <Modal
        className="modal-dialog-centered order_model align-items-center"
        centered
        style={{ zIndex: 999999 }}
        isOpen={openModal}
        toggle={() => {
          setOpenModal(false);
        }}
      >
        <div className="modal-header blueHeader">
          <h5 className="modal-title" id="exampleModalLabel">
            {selectedScript?.script}
          </h5>
          <button className="close" type="button" onClick={() => setOpenModal(false)}>
            <i className="material-icons-outlined">close</i>
          </button>
        </div>
        <div className="ModalBody p-3">
          <div className='order_top_box p-2 mb-3'>
            <h5><span>Client Name: </span>{selectedScript?.clientName}</h5>
            <h5><span>Script Name: </span>{selectedScript?.script}</h5>
          </div>
          <ul className='mb-3'>
            <li className='blue_box_wrap'>
              Lot: {selectedScript.lot?.toFixed(2)}
            </li>
            <li className='blue_box_wrap'>
              Qty: {selectedScript.qty}
            </li>
            <li className='p-0 deletOrderInput'>
              <label className=''>Price: </label>
              <Input type="text" name="" value={selectedScript.rate} placeholder='Price' onChange={(e) => setSelectedScript({ ...selectedScript, rate: e.target.value })} />
            </li>
          </ul>
          <Button className='btn_orange w-100'>Submit</Button>
        </div>
      </Modal>
    </div >
  )
}
