import { configureStore } from "@reduxjs/toolkit";
import auth from "./reducers/auth.slice";
import home from "./reducers/home.slice";
import marketWatch from "./reducers/marketWatch.slice";
import orderbook from "./reducers/orderbook.slice";
import master from "./reducers/master.slice";
import transaction from "./reducers/transaction.slice";
import finance from "./reducers/finance.slice";
import report from "./reducers/report.slice";
import setting from "./reducers/settingsSlice/setting.slice";
import settingSymbolMaxValue from "./reducers/settingsSlice/settingSymbolMaxValue.slice";
import settingClientOrderLimit from "./reducers/settingsSlice/settingClientOrderLimit.slice";
import tradeBook from "./reducers/tradeBook.slice";
import settingScriptMaxQty from "./reducers/settingsSlice/scriptMaxQtySetting.slice";
import admin from "./reducers/adminSlice/admin.slice";
import masterBrokerage from "./reducers/masterBrokerage.slice";
import masterPartnership from "./reducers/masterParnership.slice";
import common from "./reducers/common.slice";
import positionReport from "./reducers/positionReport.slice";
import valan from "./reducers/valan.slice";
import bannedBlockedScript from "./reducers/bannedBlockedScript.sclie";
import settingClientSegment from "./reducers/settingsSlice/settingClientSegment.slice";
import maxQuantityDetails from "./reducers/maxQuantityDetails.slice";
import settingOptionStrike from "store/reducers/settingsSlice/settingOptionStrikeValidation.slice";
import settingScript from "store/reducers/settingsSlice/settingScript.slice";
import cPanel from "store/reducers/settingsSlice/cPanel.slice";
export const store = configureStore({
  reducer: {
    auth,
    home,
    marketWatch,
    orderbook,
    master,
    masterBrokerage,
    common,
    transaction,
    finance,
    report,
    setting,
    tradeBook,
    positionReport,
    settingScriptMaxQty,
    settingSymbolMaxValue,
    settingClientOrderLimit,
    admin,
    valan,
    bannedBlockedScript,
    maxQuantityDetails,
    settingClientSegment,
    masterPartnership,
    settingOptionStrike,
    settingScript,
    cPanel,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
